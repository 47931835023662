import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, useTheme } from '@mui/material';
import { breakpoints } from '@mui/system';
import { TableCampaignData, TableCouponData, TableDiscountData } from '../data/Interfaces';
import { RoleRequired, Roles } from '../helpers/RoleHelper';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';


const ITEM_HEIGHT = 48;

const LongMenu = (props: {
  row: (TableDiscountData | TableCouponData | TableCampaignData), handleView(id: string): void, handleDelete(id: string): void, handleDuplicate(id: string): void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    handleClose();
    props.handleView(props.row.Id);
  }
  const handleDelete = () => {
    handleClose();

    props.handleDelete(props.row.Id);
  }

  const handleDuplicate = () => {
    handleClose();

    props.handleDuplicate(props.row.Id);
  }


  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
        <RoleRequired roles={[Roles.Editor]}>
          <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        </RoleRequired>
        <RoleRequired roles={[Roles.Editor]}>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </RoleRequired>
      </Menu>
    </div>
  );
}
const cellKeysOrder = [
  "Name",
  "Type",
  "Stacking",
  "Active",
  "Start",
  "End",
  "Priority",
];

function createHeaderCell(key: string) {
  let headerText = key.charAt(0).toUpperCase() + key.slice(1);
  switch (key) {
    case "Name":
      return (<TableCell>{headerText}</TableCell>);
    default:
      return (<TableCell align="right">{headerText}</TableCell>);
  }
}
function createHeaderCells(tableData: (TableDiscountData[] | TableCouponData[] | TableCampaignData[]), additionalHeaders: string[] = []) {
  if (tableData.length < 1) {
    return;
  }

  let row = tableData[0];
  let headerCells = [];
  for (const key of cellKeysOrder) {
    if (row.hasOwnProperty(key)) {
      headerCells.push(createHeaderCell(key));
    }
  }

  for (let i = 0; i < additionalHeaders.length; i++) {

    headerCells.push(createHeaderCell(additionalHeaders[i]));
  }

  return headerCells;
}

function createDataCell(key: string, value: any) {
  switch (key) {
    case "Name":
      return (<TableCell component="th" scope="row">{value}</TableCell>);
    case "Priority":
    case "Type":
      return (<TableCell align="right">{value}</TableCell>);
    case "Stacking":
    case "Active":
      return (<TableCell align="right">{value ? <Chip sx={{ width: '100%' }} label="yes" color="primary" /> : <Chip sx={{ width: '100%' }} label="no" color="warning" />}</TableCell>);
    case "Start":
    case "End":
      return (<TableCell align="right">{value !== null ? value.toString() : "-"}</TableCell>);
  }
}
function createDataCells(row: (TableDiscountData | TableCouponData | TableCampaignData)) {

  let cells = [];
  for (const key of cellKeysOrder) {
    if (row.hasOwnProperty(key)) {
      cells.push(createDataCell(key, row[key as (keyof typeof row)]));
    }
  }
  return cells;
}

function Row(props: { row: (TableDiscountData | TableCouponData | TableCampaignData), handleView(id: string): void, handleDuplicate(id: string): void, handleDelete(id: string): void }) {
  const { row, handleView, handleDuplicate, handleDelete } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        {createDataCells(row)}
        <TableCell align="right"><LongMenu row={row} handleView={handleView} handleDelete={handleDelete} handleDuplicate={handleDuplicate} /></TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: "100%" }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              Additional Data Here
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function DiscountTable(props: { tableData: (TableDiscountData[] | TableCouponData[] | TableCampaignData[]), handleView(id: string): void, handleDuplicate(id: string): void, handleDelete(id: string): void }) {
  const { handleDelete, handleDuplicate, handleView } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
  }, [props.tableData])

  if (props.tableData.length < 1) {
    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell> No results found </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  }
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {createHeaderCells(props.tableData, ["Actions"])}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? props.tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : props.tableData
          ).map((row) => (
            <Row key={row.Id} row={row} handleDelete={handleDelete} handleDuplicate={handleDuplicate} handleView={handleView} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={8}
              count={props.tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
