import axios, { AxiosResponse } from "axios";
import { AvailableProduct } from "../data/Interfaces";
import { Coupon, Discount, Tag, Campaign } from '@onpurple/discount-engine-types';

export interface ApiResponse<T = {}> {
  isSuccess: boolean;
  error: string;
  data?: T
}

export class ApiResponse<T = {}> {
  static Success<T = {}>(data?: T) {
    return {
      isSuccess: true, error: "", data: data
    } as ApiResponse<T>;
  }

  static Error<T = {}>(error: string) {
    return {
      isSuccess: false, error: error
    } as ApiResponse<T>;

  }
}

class ApiHelper {
  private authToken: string;
  private baseUrl: string;
  private config: any;
  private successCodes = [200, 201];

  constructor(accessToken: string | undefined) {
    this.authToken = accessToken ?? "";
    this.baseUrl = process.env.REACT_APP_BASE_API_URL ?? "";
    this.config = {
      'headers': { 'Authorization': `Bearer ${this.authToken}` },
      'Content-Type': 'application/json'
    }
  }

  isSuccess(response: AxiosResponse) {
    return this.successCodes.includes(response.status)
  }

  async get<T>(path: string): Promise<AxiosResponse<T>> {
    return axios.get(`${this.baseUrl}/${path}`, this.config)
      .then((response: AxiosResponse<T>) => response);
  }

  async post<T>(path: string, body: any): Promise<AxiosResponse<T>> {
    return axios.post(`${this.baseUrl}/${path}`, body, this.config)
      .then((response: AxiosResponse<T>) => response);
  }

  async put<T>(path: string, body: any): Promise<AxiosResponse<T>> {
    return axios.put(`${this.baseUrl}/${path}`, body, this.config)
      .then((response: AxiosResponse<T>) => response);
  }

  async delete<T>(path: string): Promise<AxiosResponse<T>> {
    return axios.delete(`${this.baseUrl}/${path}`, this.config)
      .then((response: AxiosResponse<T>) => response);
  }
}


export class PromotionsApi {
  private api: ApiHelper;
  private path = 'promotion';

  constructor(accessToken: string | undefined) {
    this.api = new ApiHelper(accessToken ?? "");
  }

  async getAll(getInactive: boolean = false): Promise<ApiResponse<Discount[]>> {
    const response = await this.api.get<{ discounts: Discount[] }>(`${this.path}`);
    return ApiResponse.Success(response.data.discounts);
  }

  async get(id: string): Promise<ApiResponse<Discount>> {
    const response = await this.api.get<{ discount: Discount }>(`${this.path}/${id}`);
    return ApiResponse.Success(response.data.discount);
  }

  async add(discount: Discount): Promise<ApiResponse<string>> {
    const response = await this.api.post<string>(`${this.path}`, { discount: discount });
    return ApiResponse.Success(response.data);
  }

  async update(discount: Discount): Promise<ApiResponse> {
    const response = await this.api.put<string>(`${this.path}`, { discount: discount });
    return ApiResponse.Success(response.data);
  }

  async delete(id: string): Promise<ApiResponse> {
    await this.api.delete(`${this.path}/${id}`);
    return ApiResponse.Success();
  }

}

export class CampaignsApi {
  private api: ApiHelper;
  private path = 'campaign';

  constructor(accessToken: string | undefined) {
    this.api = new ApiHelper(accessToken ?? "");
  }

  async getAll(): Promise<ApiResponse<Campaign[]>> {
    const response = await this.api.get<{ campaigns: Campaign[] }>(`${this.path}`);
    return ApiResponse.Success(response.data.campaigns);
  }

  async get(id: string): Promise<ApiResponse<Campaign>> {
    const response = await this.api.get<{ campaign: Campaign }>(`${this.path}/${id}`);
    return ApiResponse.Success(response.data.campaign);
  }

  async add(campaign: Campaign): Promise<ApiResponse<string>> {
    const response = await this.api.post<{ Id: string }>(`${this.path}`, { campaign: campaign });
    return ApiResponse.Success(response.data.Id);
  }

  async update(campaign: Campaign): Promise<ApiResponse> {
    const response = await this.api.put<string>(`${this.path}`, { campaign: campaign });
    return ApiResponse.Success(response.data);
  }

  async delete(id: string): Promise<ApiResponse> {
    await this.api.delete(`${this.path}/${id}`);
    return ApiResponse.Success();
  }

}


export class ProductsApi {
  private api: ApiHelper;
  private path = 'product';

  constructor(accessToken: string | undefined) {
    this.api = new ApiHelper(accessToken ?? "");
  }

  async getAll(): Promise<ApiResponse<AvailableProduct[]>> {
    const response = await this.api.get<{ products: AvailableProduct[] }>(`/${this.path}`);
    return ApiResponse.Success(response.data.products);
  }
}

export class CouponsApi {
  private api: ApiHelper;
  private path = 'coupon';

  constructor(accessToken: string | undefined) {
    this.api = new ApiHelper(accessToken ?? "");
  }

  async getAll(): Promise<ApiResponse<Coupon[]>> {
    const response = await this.api.get<Coupon[]>(`${this.path}`);
    return ApiResponse.Success(response.data);
  }

  async get(id: string): Promise<ApiResponse<Coupon>> {
    const response = await this.api.get<Coupon>(`${this.path}/${id}`);
    return ApiResponse.Success(response.data);
  }

  async getByCode(code: string): Promise<ApiResponse<Coupon>> {
    const response = await this.api.get<Coupon>(`${this.path}/code/${code}`);
    return ApiResponse.Success(response.data);
  }

  async add(coupon: Coupon): Promise<ApiResponse<string>> {
    const response = await this.api.post<string>(`${this.path}`, coupon);
    return ApiResponse.Success(response.data);
  }

  async update(coupon: Coupon): Promise<ApiResponse> {
    const response = await this.api.put<string>(`${this.path}`, coupon);
    return ApiResponse.Success(response.data);
  }

  async delete(id: string): Promise<ApiResponse> {
    await this.api.delete(`${this.path}/${id}`);
    return ApiResponse.Success();
  }

  async validateCodes(codes: string[]): Promise<ApiResponse<{ hasErrors: boolean, badCodes: string[] }>> {
    const response = await this.api.post<{ hasErrors: boolean, badCodes: string[] }>(`/${this.path}/validate`, codes);
    return ApiResponse.Success(response.data);
  }

}

export class TagsApi {
  private api: ApiHelper;
  private path = 'tag';

  constructor(accessToken: string | undefined) {
    this.api = new ApiHelper(accessToken ?? "");
  }

  async getAll(): Promise<ApiResponse<Tag[]>> {
    const response = await this.api.get<{ tags: Tag[] }>(`/${this.path}`);
    return ApiResponse.Success(response.data.tags);
  }

  async add(name: string): Promise<ApiResponse<string>> {
    const response = await this.api.post<string>(`/${this.path}`, { tag: name });
    return ApiResponse.Success(response.data);
  }
}