import * as React from 'react';
import { Box, Button, ButtonGroup, Card, CardContent, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Title from "../../../../components/Title";
import { useProducts } from '../../../../helpers/apiHooks/useProducts';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Field } from '../../../../data/Field';
import CreateAlert, { WebsiteAlert } from '../../../../components/Alerts';
import AvailableProductSelect from '../../AvailableProductSelect';
import SelectedProductList from '../../SelectedProductList';
import { AvailableProduct, BMSMIncrement, BMSMVariant, BuyMoreSaveMoreMethod, Discount, DiscountLogic } from '@onpurple/discount-engine-types';
import { AlertType } from '../../../../data/Enums';

export default function BuyMoreSaveMoreSection(props:
  {
    discount: Discount,
    handleUpdate(details: BuyMoreSaveMoreMethod): void,
    handleErrors(error: string[]): any
  }) {
  const { discount, handleUpdate, handleErrors } = props;
  const productsApi = useProducts();
  const groupMethod = discount.DiscountMethod as BuyMoreSaveMoreMethod | null;
  const [selectedProducts, setSelectedProducts] = React.useState<Field<AvailableProduct[]>>(new Field("Selected Products", [] as AvailableProduct[]));
  const [variant, setVariant] = React.useState<Field<BMSMVariant>>(new Field("Variant", groupMethod?.Variant ?? BMSMVariant.Unknown));
  const [cartMinimum, setCartMinimum] = React.useState<Field<number>>(new Field("Cart Minimum", groupMethod?.CartMinimum ?? 0));
  const [increments, setIcrements] = React.useState<Field<BMSMIncrement[]>>(new Field("Buy More Save More Increments", groupMethod?.Increments ?? [] as BMSMIncrement[]));
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: "",
    open: false
  } as WebsiteAlert)

  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });
  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });

  const handleSetCartMinimum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setCartMinimum(cartMinimum.Update(parseFloat(value.toFixed(2)), value < 0 ? 'Cart minumum can not be negative' : ""));
  }

  const handleSetSelectedProducts = (products: AvailableProduct | AvailableProduct[] | null) => {
    products = products as AvailableProduct[];
    let updatedProducts = selectedProducts.Update(products.length > 0 ? [...products] : [], products.length > 0 ? "" : "Must select at least product");
    setSelectedProducts(updatedProducts);
    updatePromotion();
  }

  const handleRemoveProduct = (id: string) => {
    setSelectedProducts(selectedProducts.Update(selectedProducts.Value.filter(x => x.Id !== id)));
    updatePromotion();
  }

  const handleVariantChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setVariant(variant.Update(value === 'products' ? BMSMVariant.Products : BMSMVariant.CartValue))
    updatePromotion();
  }

  const handleUpdateIncrementActive = (index: number) => (
    event: React.MouseEvent<HTMLElement>,
    value: boolean
  ) => {
    let toAdd = increments.Value;
    toAdd[index].Removed = value;
    setIcrements(increments.Update(toAdd));
    updatePromotion();
  }

  const handleUpdateIncrementDisplayName = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event;

    let toAdd = increments.Value;
    toAdd[index].DisplayName = target.value;
    setIcrements(increments.Update(toAdd));

    updatePromotion();
  }

  const handleUpdateIncrementPriority = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event;

    let toAdd = increments.Value;
    toAdd[index].Priority = parseInt(target.value);
    setIcrements(increments.Update(toAdd));

    updatePromotion();
  }

  const handleUpdateIncrementValue = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event;

    let toAdd = increments.Value;
    toAdd[index].Value = toAdd[index].Logic === DiscountLogic.Relative ? parseInt(target.value) : parseFloat(target.value);
    setIcrements(increments.Update(toAdd));

    updatePromotion();
  }

  const handleUpdateIncrementLogic = (index: number) => (
    event: React.MouseEvent<HTMLElement>,
    value: number
  ) => {
    let toAdd = increments.Value;
    toAdd[index].Logic = (value === 1) ? DiscountLogic.Absolute : DiscountLogic.Relative;
    setIcrements(increments.Update(toAdd));
    updatePromotion();
  }

  const handleRemoveIncrement = () => {
    let toAdd = increments.Value;

    if (toAdd.length > 1) {
      toAdd.pop();
    } else {
      handleAlertOpen(AlertType.warning, "Must have at least 1 discount");
    }
    setIcrements({ Value: toAdd } as Field<BMSMIncrement[]>);
  }

  const handleAddIncrement = () => {
    setIcrements({ Value: [...increments.Value, {} as BMSMIncrement] } as Field<BMSMIncrement[]>);
  }

  const updatePromotion = () => {
    handleUpdate({
      ...groupMethod,
      Variant: variant.Value,
      CartMinimum: cartMinimum.Value,
      Products: selectedProducts.Value,
      Increments: increments.Value
    });
  }

  const hasError = (field: Field<any>) => {
    return !field.Error ? false : field.Error !== "";
  }

  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Title>Buy More Save More Discount Details</Title>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={7}>

            <ToggleButtonGroup
              color="primary"
              value={variant.Value}
              exclusive
              onChange={handleVariantChange}
            >
              <ToggleButton value={BMSMVariant.Products}>Product Quantity</ToggleButton>
              <ToggleButton value={BMSMVariant.CartValue}>Cart Value</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={5}>
            <TextField
              type="number"
              fullWidth id="outlined-search"
              label="Cart Minimum"
              value={cartMinimum.Value}
              onChange={handleSetCartMinimum}
              error={cartMinimum ? hasError(cartMinimum) : false}
              helperText={cartMinimum?.Error}
            />
          </Grid>
          <Grid item xs={12}>
            <AvailableProductSelect
              multiple={true}
              handleSelected={handleSetSelectedProducts}
              label='Select Gifts'
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {increments.Value.map((increment, index) => (
            <Grid item xs={12}>
              <Card sx={{ mb: 0 }} variant="outlined">
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ pb: 0 }}>
                    When you buy {index + 1}
                  </Typography>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>

                      <ToggleButtonGroup
                        color="primary"
                        value={increment.Removed}
                        exclusive
                        size="small"
                        onChange={handleUpdateIncrementActive(index)}
                      >
                        <ToggleButton value={false}>Discount</ToggleButton>
                        <ToggleButton value={true}>Do Nothing</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    {(!increment.Removed) ? (
                      <>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth id="outlined-search"
                            label="Display Name"
                            value={increment.DisplayName}
                            onChange={handleUpdateIncrementDisplayName(index)}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth type="number"
                            id="outlined-search"
                            label="Priority"
                            value={increment.Priority}
                            error={false}
                            onChange={handleUpdateIncrementPriority(index)}
                            disabled={false} />
                        </Grid>


                        <Grid item xs={7}>
                          <TextField
                            fullWidth type="number"
                            id="outlined-search"
                            label={"Value"}
                            InputProps={(increment.Logic === 'absolute') ? {
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            } : {
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            value={increment.Value}
                            onChange={handleUpdateIncrementValue(index)}
                            error={false}
                            helperText={""}
                            disabled={false} />
                        </Grid>
                        <Grid item xs={5}>
                          <ToggleButtonGroup
                            color="primary"
                            value={increment.Logic}
                            exclusive
                            onChange={handleUpdateIncrementLogic(index)}
                          >
                            <ToggleButton value={DiscountLogic.Absolute}>Absolute</ToggleButton>
                            <ToggleButton value={DiscountLogic.Relative}>Relative</ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                      </>
                    ) : <></>}

                  </Grid>

                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item xs={12}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ float: 'right' }}>
              <Button startIcon={<RemoveIcon />} sx={{ pr: 0 }} onClick={handleRemoveIncrement} />
              <Button startIcon={<AddIcon />} sx={{ pr: 0 }} onClick={handleAddIncrement} />
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Box>
          <SelectedProductList
            title='Selected Products'
            selectedProducts={selectedProducts.Value}
            handleDeleteProduct={handleRemoveProduct} />
        </Box>
      </Grid>
      <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
    </Grid>
  );
}



