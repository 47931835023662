import * as React from 'react';
import { Box, Grid, TextField } from "@mui/material";
import Title from "../../../../components/Title";
import { Field } from '../../../../data/Field';
import { useProducts } from '../../../../helpers/apiHooks/useProducts';
import AvailableProductSelect from '../../AvailableProductSelect';
import SelectedProductList from '../../SelectedProductList';
import GroupDiscountCreate from './GroupDiscountCreate';
import { AvailableProduct, Discount, ProductGroup, ProductGroupMethod } from '@onpurple/discount-engine-types';


export default function ProductGroupSection(props:
  {
    discount: Discount,
    handleUpdate(details: ProductGroupMethod): void,
    handleErrors(error: string[]): any
  }) {
  const { discount, handleUpdate, handleErrors } = props;
  const productsApiHelper = useProducts();
  const groupMethod = discount.DiscountMethod as ProductGroupMethod;
  const [discountedGroups, setDiscountedGroups] = React.useState<Field<ProductGroup[]>>(new Field<ProductGroup[]>("Discounted Products", [] as ProductGroup[]));
  const [notDiscountedProducts, setNotDiscountedProducts] = React.useState<Field<AvailableProduct[]>>(new Field<AvailableProduct[]>("Not Discounted Products", [] as AvailableProduct[]));
  const [cartMinimum, setCartMinimum] = React.useState<Field<number>>(new Field<number>("Cart Minimum", 0));


  const handleSetCartMinimum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(parseFloat(event.target.value).toFixed(2));
    setCartMinimum(cartMinimum.Update(value, value < 0 ? "Cart minumum can not be negative" : ""));
  }


  const handleSetNotDiscountedProducts = (products: AvailableProduct[]) => {
    setNotDiscountedProducts(notDiscountedProducts.Update(products, products.length < 0 ? "Must select at least product" : ""));
    updatePromotion();
  }

  const handleRemoveNotDiscountedProduct = (id: string) => {
    setNotDiscountedProducts(notDiscountedProducts.Update(notDiscountedProducts.Value.filter(x => x.Id !== id)));
    updatePromotion();
  }

  const handleUpdateEditingGroup = (group: ProductGroup) => {
    setDiscountedGroups(discountedGroups.Update([...discountedGroups.Value, group]));
  }

  const updatePromotion = () => {
    // handleUpdate({
    //   ...groupMethod,
    //   DiscountedProducts: sel.Value,
    //   NotDiscounted: notDiscountedProducts.Value,
    //   CartMinimum: cartMinimum.Value,
    //   AndOrLogic: AndOrLogic.and
    // });
  }


  const hasError = (field: Field<any>) => {
    return !field.Error ? false : field.Error !== "";
  }

  React.useEffect(() => {
    let errors = [] as string[];

    if (cartMinimum.Error && cartMinimum.Error !== "") {
      errors.push(cartMinimum.Error);
    }

    handleErrors(errors);
  }, [cartMinimum])


  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Title>Product Group Discount Details</Title>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AvailableProductSelect
              multiple={true}
              label='Select Not Discounted Products'
              handleSelected={handleSetNotDiscountedProducts}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <SelectedProductList title='Not Discounted Products' selectedProducts={notDiscountedProducts.Value} handleDeleteProduct={handleRemoveNotDiscountedProduct} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <GroupDiscountCreate
              notDiscountedProducts={notDiscountedProducts.Value}
              handleUpdate={handleUpdateEditingGroup} />
          </Grid>



          <Grid item xs={6}>
            <TextField
              type="number"
              fullWidth id="outlined-search"
              label="Cart Minimum"
              value={cartMinimum.Value}
              onChange={handleSetCartMinimum}
              error={cartMinimum ? hasError(cartMinimum) : false}
              helperText={cartMinimum?.Error}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}



