import * as React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Chip, Button } from '@mui/material';
import { Campaign, Discount } from '@onpurple/discount-engine-types';
import Title from "../../../components/Title";
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from '../../../components/LoadingSpinner';
import CreateAlert, { WebsiteAlert } from '../../../components/Alerts';
import { AlertType } from '../../../data/Enums';
import DateModal from './Modal/DateModal';
import { usePromotions } from '../../../helpers/apiHooks/usePromotions';
import { useCampaigns } from '../../../helpers/apiHooks/useCampaigns';
import dayjs from "dayjs";


function Row(props: { discount: Discount, selectedRows: string[], setSelected(selected: string[]): void }) {
  const { discount, selectedRows, setSelected } = props;

  const isSelected = (name: string) => selectedRows.indexOf(name) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, discount.Id)}
        role="checkbox"
        aria-checked={isSelected(discount.Id)}
        tabIndex={-1}
        key={discount.Id}
        selected={isSelected(discount.Id)}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isSelected(discount.Id)}
            inputProps={{
              'aria-labelledby': discount.Id,
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {discount.Name}
        </TableCell>
        <TableCell align="right">{discount.DiscountType}</TableCell>
        <TableCell align="right">{discount.Stacking ? <Chip sx={{ width: '100%' }} label="yes" color="primary" /> : <Chip sx={{ width: '100%' }} label="no" color="warning" />}</TableCell>
        <TableCell align="right">{discount.Active ? <Chip sx={{ width: '100%' }} label="yes" color="primary" /> : <Chip sx={{ width: '100%' }} label="no" color="warning" />}</TableCell>
        <TableCell align="right">{discount.StartDate !== null ? dayjs(discount.StartDate).format('MM/DD/YYYY') : "-"}</TableCell>
        <TableCell align="right">{discount.ExpirationDate !== null ? dayjs(discount.ExpirationDate).format('MM/DD/YYYY') : "-"}</TableCell>
        <TableCell align="right">{discount.Priority}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

enum BulkAction {
  activate, deactivate, changeDates, duplicate, remove
}

function TableToolbar(props: { numSelected: number, handleBulkAction(action: BulkAction): void }) {
  const { numSelected, handleBulkAction } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Title sx={{ flex: '1 1 100%' }}>Discounts</Title>
      )}
      {numSelected > 0 ? (
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center">
          <Grid item xs={"auto"}>
            <Tooltip title="Activate">
              <Button variant="contained" onClick={() => { handleBulkAction(BulkAction.activate) }}>Activate</Button>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Deactivate">
              <Button variant="outlined" onClick={() => { handleBulkAction(BulkAction.deactivate) }}>Deactivate</Button>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Change Dates">
              <Button variant="outlined" onClick={() => { handleBulkAction(BulkAction.changeDates) }}>Change Dates</Button>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Remove">
              <Button variant="outlined" onClick={() => { handleBulkAction(BulkAction.remove) }}>Remove from Campaign</Button>
            </Tooltip>
          </Grid>
          {/* Im hoping we can not include duplicate action */}
          {/* <Grid item xs={"auto"}>
            <Tooltip title="Delete">
              <Button variant="outlined" onClick={() => { handleBulkAction(BulkAction.duplicate) }}>Duplicate</Button>
            </Tooltip>
          </Grid> */}
        </Grid>
      ) : (
        <></>
      )}
    </Toolbar>
  );
}

export interface CampaignDiscountTableProps {
  discounts: Discount[],
  campaign: Campaign,
  setHasChanges(boolean: boolean): void,
}

export default function CampaignDiscountTable(props: CampaignDiscountTableProps) {
  const { discounts, campaign, setHasChanges } = props;
  const apiDiscountHelper = usePromotions();
  const apiCampaignHelper = useCampaigns();
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [modalActive, setModalActive] = React.useState<boolean>(false);
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: "",
    open: false
  } as WebsiteAlert)

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleSelectAll = () => {
    if (discounts.length > 0 && selectedIds.length === discounts.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(discounts.map(x => x.Id));
    }
  }

  const handleBulkEdit = (action: BulkAction) => {
    function bulkUpdatePromo() {
      return new Promise<void>((resolve) => {
        setSpinnerActive(true);
        for (let x = 0; x < discounts.length; x++) {
          apiDiscountHelper.update(discounts[x]);
        }
        resolve();
      }).then(() => {
        setSpinnerActive(false);
        setSelectedIds([]);
        setHasChanges(true);
      }).catch(error => {
        handleAlertOpen(AlertType.error, error);
        setSpinnerActive(false);
      });
    }
    switch (action) {
      case BulkAction.deactivate:
        for (let i in discounts) {
          if (selectedIds.includes(discounts[i].Id)) {
            discounts[i].Active = false;
          }
        }
        bulkUpdatePromo();
        break;
      case BulkAction.activate:
        for (let i in discounts) {
          if (selectedIds.includes(discounts[i].Id)) {
            discounts[i].Active = true;
          }
        }
        bulkUpdatePromo();
        break;
      case BulkAction.changeDates:
        handleModalActive();
        break;
      case BulkAction.remove:
        setSpinnerActive(true);
        for (let i = 0; i < selectedIds.length; i++) {
          for (let x = 0; x < campaign.Discounts.length; x++) {
            if (selectedIds[i] === campaign.Discounts[x].Id) {
              campaign.Discounts.splice(x, 1);
            }
          }
        }
        apiCampaignHelper.update(campaign).then((response) => {
          setSpinnerActive(false);
          handleAlertOpen(AlertType.success, "Campaign Updated");
          setSelectedIds([]);
        }).catch(error => {
          handleAlertOpen(AlertType.error, error);
          setSpinnerActive(false);
        })
        break;
      case BulkAction.duplicate:
        // I am hoping we can get away with not having duplicate action
        alert(`Need to set up bulk action Duplicate ${selectedIds.length} item`);
        break;
      default:
        break;
    }
  }

  const handleSetSelected = (selected: string[]) => setSelectedIds(selected);

  const handleModalClose = () => {
    setModalActive(false)
  }

  const handleModalActive = () => {
    setModalActive(true)
  }

  return (
    <>
      <TableToolbar numSelected={selectedIds.length} handleBulkAction={handleBulkEdit} />
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selectedIds.length > 0 && selectedIds.length < discounts?.length}
                  checked={discounts?.length > 0 && selectedIds.length === discounts?.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Stacking</TableCell>
              <TableCell align="right">Active</TableCell>
              <TableCell align="right">Start</TableCell>
              <TableCell align="right">End</TableCell>
              <TableCell align="right">Priority</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts?.map((discount) => (
              <Row key={discount.Id} discount={discount} selectedRows={selectedIds} setSelected={handleSetSelected} />
            ))}
          </TableBody>
        </Table>
        <LoadingSpinner active={spinnerActive} />
        <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
        <DateModal active={modalActive} handleClose={handleModalClose} selectedIds={selectedIds} campaign={props.campaign} setHasChanges={setHasChanges} />
      </TableContainer>
    </>
  );
}
