import { Paper, Box, FormControlLabel, Grid, Stack, Switch, TextField, Button } from "@mui/material";
import React from "react";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { Campaign, Discount } from '@onpurple/discount-engine-types';
import Title from "../../components/Title";
import CreateAlert, { WebsiteAlert } from "../../components/Alerts";
import { AlertType } from "../../data/Enums";
import LoadingSpinner from '../../components/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import { useCampaigns } from "../../helpers/apiHooks/useCampaigns";


export default function CampaignAdd() {
  const [campaign, setCampaign] = React.useState<Campaign>({} as Campaign);
  const apiHelper = useCampaigns();
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: '',
    open: false
  } as WebsiteAlert);
  const navigate = useNavigate();

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaign({ ...campaign, Name: event.target.value });
    setHasChanges(true);
  };

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaign({ ...campaign, Priority: parseInt(event.target.value) });
    setHasChanges(true);
  };

  const handleActiveChange = () => {
    setCampaign({ ...campaign, Active: !campaign.Active });
    setHasChanges(true);
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    setCampaign({ ...campaign, StartDate: date ?? undefined });
    setHasChanges(true);
  };

  const handleExpirationDateChange = (date: Dayjs | null) => {
    setCampaign({ ...campaign, ExpirationDate: date ?? undefined });
    setHasChanges(true);
  };

  const handleSave = () => {

    setSpinnerActive(true);
    apiHelper.add(campaign)
      .then(response => {
        if (response.isSuccess) {
          handleAlertOpen(AlertType.success, "Campaign succesfully created")
          setSpinnerActive(false);
          navigate(`/campaigns`);
        } else {
          handleAlertOpen(AlertType.error, response.error);
        }
      })
      .catch(error => {
        handleAlertOpen(AlertType.error, error);
        setSpinnerActive(false);
      })
  }

  React.useEffect(() => {
    setCampaign({
      Id: "",
      Name: "",
      Priority: 0,
      Active: false,
      StartDate: undefined,
      ExpirationDate: undefined,
      Discounts: [] as Discount[],
    } as Campaign)
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%', mt: 2, p: 3 }}>
        <Paper sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs>
              <Title>Create New Campaign</Title>
            </Grid>
            <Grid item xs="auto">
              <Button variant="contained" disabled={!hasChanges} onClick={handleSave}>Save Campaign</Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item xs={6}>
              <TextField fullWidth id="outlined-search" label="Name" value={campaign.Name} onChange={handleNameChange} />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth type="number" id="outlined-search" label="Priority" value={campaign.Priority} onChange={handlePriorityChange} />
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" spacing={2} sx={{ ml: 3, pt: 1 }}>
                <FormControlLabel control={<Switch checked={campaign.Active} />} label="Active" onChange={handleActiveChange} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <MobileDatePicker
                inputFormat="MMMM DD, YYYY"
                label="Start Date"
                value={campaign.StartDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField fullWidth id="start-date" {...params} />}
              />
            </Grid>
            <Grid item xs={6}>
              <MobileDatePicker
                inputFormat="MMMM DD, YYYY"
                label="End Date"
                value={campaign.ExpirationDate}
                onChange={handleExpirationDateChange}
                renderInput={(params) => <TextField fullWidth id="expire-date" {...params} />}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <LoadingSpinner active={spinnerActive} />
      <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
    </LocalizationProvider>
  )
}