import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import DiscountTable from '../components/DiscountTable';
import Title from '../components/Title';
import { Tooltip, Typography } from '@mui/material';
import TableFilter, { TableFilterType, SelectedFilters } from './components/TableFilter';
import SelectedFiltersDisplay from './components/SelectedFiltersDisplay';
import LoadingSpinner from '../components/LoadingSpinner';
import { Convert, TableDiscountData } from '../data/Interfaces';
import FilterHelper from '../helpers/FilterHelper';
import DeleteDialog from './components/DeleteDialog';
import Alerts, { WebsiteAlert } from '../components/Alerts';
import { AlertType } from "../data/Enums";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { usePromotions } from '../helpers/apiHooks/usePromotions';
import { useOktaAuth } from '@okta/okta-react';
import { RoleRequired, Roles } from '../helpers/RoleHelper';
import { Discount } from '@onpurple/discount-engine-types';
const filterHelper = new FilterHelper();

export default function Promos() {
  const { oktaAuth, authState } = useOktaAuth();
  const apiHelper = usePromotions();
  const [rows, setRows] = React.useState<TableDiscountData[]>([]);
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters[]>([{ name: "active", value: 'true' }]);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [deleteDialogActive, setDeleteDialogActive] = React.useState(false);
  const [deleteDialogId, setDeleteDialogId] = React.useState('');
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: '',
    open: false
  } as WebsiteAlert);

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleDelete = (id: string) => {
    setDeleteDialogId(id);
    setDeleteDialogActive(true);
  }

  const handleDeleteDialog = (id: string) => {
    setSpinnerActive(true);
    setDeleteDialogActive(false);

    alert("move delete over to API helper");

    // discountEngineApiHelper.deletePromo(id).then(() => {
    //   let updatedRows = rows.filter(row => {
    //     return row.Id !== id;
    //   })
    //   setRows(updatedRows);
    //   setSpinnerActive(false);
    //   handleAlertOpen(AlertType.success, 'Entry succesfully deleted');
    // });
  }

  const handleCloseDialog = () => setDeleteDialogActive(false);

  const handleView = (id: string) => {
    window.location.replace(`/promos/${id}`);
  }

  const handleDuplicate = (id: string) => { alert(`Need to handle duplicate for id ${id}`) }



  const discountsToPromoTables = (discounts: Discount[]) => {
    return discounts.map(discount => {
      return {
        Id: discount.Id,
        Type: discount.DiscountType,
        Stacking: discount.Stacking,
        Active: discount.Active,
        Name: discount.Name,
        Start: discount.StartDate,
        End: discount.ExpirationDate,
        Priority: discount.Priority
      } as TableDiscountData;
    });
  }


  const filterResults = () => {

    setSpinnerActive(true);
    apiHelper.getAll().then((rows) => {
      if (rows.isSuccess) {
        filterHelper.filterRows(
          selectedFilters,
          discountsToPromoTables(rows.data ?? [])
        ).then((filteredRows) => {
          setRows(filteredRows as TableDiscountData[]);
          setSpinnerActive(false);
        });
      }

    })
      .catch(exception => {
        console.log(exception);
        handleAlertOpen(AlertType.error, "Error calling API")
      })
      .finally(() => setSpinnerActive(false));
  }

  const handleSaveFilters = (filters: SelectedFilters[]) => setSelectedFilters(filters);

  const handleRemoveFilter = (filter: SelectedFilters) => {
    const remainingFilters = selectedFilters?.filter(value => value !== filter) ?? null;
    setSelectedFilters(remainingFilters);
  }

  React.useEffect(() => {
    filterResults();
  }, [selectedFilters]);

  return (
    <RoleRequired roles={[Roles.Viewer]}>
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <Grid container spacing={3}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ p: 3 }}>

              <Grid container>
                <Grid item xs>
                  <Title>Site Promotions</Title>
                  <Typography color="text.secondary">Site coupons are discounts used on the website when enterning a promo code during checkout</Typography>
                </Grid>
                <Grid item xs={"auto"}>
                  <TableFilter type={TableFilterType.Promotions} setFilters={handleSaveFilters} />
                </Grid>
              </Grid>
              <SelectedFiltersDisplay filters={selectedFilters} handleRemove={handleRemoveFilter} />
              <Alerts alert={websiteAlert} handleClose={handleAlertClose} />
              <DiscountTable tableData={rows} handleView={handleView} handleDelete={handleDelete} handleDuplicate={handleDuplicate} />
            </Paper>
          </Box>
        </Grid>
        <LoadingSpinner active={spinnerActive} />
        <DeleteDialog id={deleteDialogId} active={deleteDialogActive} handleDelete={handleDeleteDialog} handleClose={handleCloseDialog} />
        <RoleRequired roles={[Roles.Editor]}>
          <Tooltip title="Add Promotion">
            <Fab
              color="primary"
              aria-label="add"
              href='promos/add'
              sx={{
                margin: 0,
                top: 'auto',
                right: 40,
                bottom: 30,
                left: 'auto',
                position: 'fixed',
              }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </RoleRequired>
      </Container>
    </RoleRequired>
  );
}
