import axios from 'axios';
import dayjs from 'dayjs';
import { DE_Api_Response, DE_Campaign, DE_Convert, DE_Coupon, DE_Discount, DE_GetCampaignsResponse, DE_GetCouponResponse, DE_GetCouponsResponse, DE_GetDiscountsResponse, DE_GetTagsResponse, DE_PostCampaignResponse, DE_PostDiscountResponse, DE_PostTagResponse, DE_Tag, DE_UpdateCampaignResponse, DE_UpdateDiscountResponse } from '../data/Dtos';
import { BMSMVariant, DiscountLogic, DiscountType } from '../data/Enums';
import { APIResponse, AvailableProduct, BMSMIncrement, BuyMoreSaveMoreMethod, Campaign, Convert, CreditMethod, Discount, DiscountTag, GiftItemMethod, ProductGroupMethod, ProductMethod, Response, TableCampaignData, TableCouponData, TableDiscountData } from '../data/Interfaces';

class APIPrepper {
    static authToken = "eyJraWQiOiJCSG4tVlc1Y3l5TEZ1SUp0TTJyNl9CN3dteEtyd3NWeWJHeVJhcmNHOFJJIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjJjT3RQOVRSSUJub3d4dWtXYUR6Y201bng5aFdmM0VLVU9MWGl1ank1MWciLCJpc3MiOiJodHRwczovL29ucHVycGxlLm9rdGEuY29tL29hdXRoMi9hdXM1N3R5aWI4SGlnM0ZFRDM1NyIsImF1ZCI6Imh0dHBzOi8vb25wdXJwbGUub2t0YS5jb20vY3NyX3Rvb2xzIiwiaWF0IjoxNjcxMDQxNDAxLCJleHAiOjE2NzExMTM0MDEsImNpZCI6IjBvYTdvYmpkbGRqYWhrUWRZMzU3IiwidWlkIjoiMDB1MWM3Y3RneUxOV3BRNzAzNTciLCJzY3AiOlsicHJvZmlsZSIsIm9wZW5pZCIsImVtYWlsIl0sImF1dGhfdGltZSI6MTY2OTIzODA4OSwic3ViIjoiYW5kcmV3LmdAcHVycGxlLmNvbSJ9.rMeukX5l9DrYKudPG1A1BAYuS1sDP1Ei1_m525QklncQSMgL2emwLtlRk_8i7R9Pw09F6upvH1uT1GvMqhsjuuXmB1uHLWpCxuX9azVrbq8WbhtTYcJbmA0a1B80S1tpyXZD_VlMliUhkVaR7D6vsft-B7DtSvHgXdRNdwYhnZNyKv2--YMjZ_dp_BoOlhxM4EcBJLHPxbUEUYDYl5E0Hry2ev4eOjLrvIWX6X2qrnk9cJsfRZ5XemgDersRvJ-EWIcSS-fTDoGrWv9aSs0vH3Gk3uaYos_OD_ePxRTcIFmXrUApL8s-jtpDvLPWwXAqFtLhhgguVQY2YrU42d80kw";
    static baseUrl = 'http://localhost:8080/https://aq5gol6pok.execute-api.us-west-2.amazonaws.com/default';

    static async Post<T>(path: string, body: any) {
        const data = JSON.stringify(body);
        const options = {
            'headers': { 'Authorization': `Bearer ${this.authToken}` },
            'Content-Type': 'application/json'
        }

        let response = await axios.post(`${this.baseUrl}/${path}`, data, options);
        return response.data as T;
    }

    static async Get<T>(path: string) {
        const options = {
            headers: { 'Authorization': `Bearer ${this.authToken}` },
        }

        let response = await axios.get(`${this.baseUrl}/${path}`, options);
        return response.data as T;
    }

    static async Delete<T>(path: string) {
        const options = {
            headers: { 'Authorization': `Bearer ${this.authToken}` },
        }

        let response = await axios.delete(`${this.baseUrl}/${path}`, options);
        return response.data as T;
    }
}
export default class DiscountEngineApiHelper {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        // anything needed to start up class
    }

    async getRawPromotions(): Promise<APIResponse<DE_Discount[]>> {
        let response = await APIPrepper.Get<DE_GetDiscountsResponse>('discounts');
        return response.discounts ?
            Response.success(response.discounts) :
            Response.error("Error getting discounts");
    }

    async getPromotions(): Promise<APIResponse<TableDiscountData[]>> {
        let response = await this.getRawPromotions();
        return response.isSuccess ?
            Response.success(Convert.ToTableDiscounts(response.value)) :
            Response.error("Error getting discounts");
    }

    async getPromotion(id: string): Promise<APIResponse<Discount>> {
        let response = await APIPrepper.Get<DE_GetDiscountsResponse>('discounts');

        if (!response.discounts) {
            return Response.error("Error getting discounts");;
        }

        const discount = response.discounts.find((x: any) => x.id === id);

        if (typeof discount === 'undefined') {
            return Response.error("Error getting discounts");;
        }

        return Response.success(Convert.ToDiscount(discount))
    }

    async getRawCoupons(): Promise<APIResponse<DE_Coupon[]>> {
        let response = await APIPrepper.Get<DE_GetCouponsResponse>('coupons');
        return response.coupons ?
            Response.success(response.coupons) :
            Response.error("Error getting coupons");
    }

    async AddTags(tags: DiscountTag[]) {
        for (let i = 0; i < tags.length; i++) {
            let response = await this.addTag(tags[i]);
        }

        return Response.success();
    }

    async addTag(tag: DiscountTag): Promise<APIResponse<DiscountTag>> {
        return Response.error<DiscountTag>("Need to add discount tag via api helper")
    }

    async getTags(): Promise<DiscountTag[]> {
        return [{ Text: "Need to get tags from API Helper" }];
    }

    async savePromotion(discount: Discount): Promise<APIResponse<string>> {

        let apiResponse = await APIPrepper.Get<DE_PostDiscountResponse>('discounts');

        if (typeof apiResponse.discounts === "undefined") {
            return Response.error("Discount not found");
        }

        const promotion = apiResponse.discounts.find((x: DE_Discount) => x.id === discount.Id);

        if (typeof promotion === "undefined") {
            return Response.error("Discount not found");
        }

        let updated = DE_Convert.UpdateDiscount(promotion, discount);

        const response = await APIPrepper.Post<DE_UpdateDiscountResponse>('discounts', { discount: updated });
        return response.success ? Response.success<string>() : Response.error(response.error);
    }

    async saveCampaign(campaign: Campaign): Promise<APIResponse<string>> {

        let apiResponse = await APIPrepper.Get<DE_PostCampaignResponse>('campaigns');

        if (typeof apiResponse.campaigns === "undefined") {
            return Response.error("Campaign not found");
        }

        const selectedCampaign = apiResponse.campaigns.find((x: DE_Campaign) => x.id === campaign.Id);

        if (typeof selectedCampaign === "undefined") {
            return Response.error("Campaign not found");
        }

        let updated = DE_Convert.UpdateCampaign(selectedCampaign, campaign);

        const response = await APIPrepper.Post<DE_UpdateCampaignResponse>('campaigns', { campaign: updated });
        return response.success ? Response.success<string>() : Response.error(response.error);
    }

    async saveNewCampaign(campaign: Campaign): Promise<APIResponse<string>> {

        let updated = DE_Convert.ToCampaign(campaign)

        const response = await APIPrepper.Post<DE_UpdateCampaignResponse>('campaigns', {campaign: updated});

        if (!response.success) {
            return Response.error(response.error)
        } else {
            return Response.success(response.campaign.id)
        }
    }

    async getCampaigns() {

        let getResponse = await APIPrepper.Get<DE_GetCampaignsResponse>('campaigns');
        let response = [] as TableCampaignData[];

        if (getResponse.success) {
            for (let i = 0; i < getResponse.campaigns.length; i++) {
                response.push(Convert.ToTableCampaign(getResponse.campaigns[i]));
            }
        }

        return response;
    }

    async getCoupons(): Promise<APIResponse<TableCouponData[]>> {
        let couponsResponse = await this.getRawCoupons();

        if (!couponsResponse.isSuccess) {
            return Response.error('Failed to retrieve raw coupons while compiling coupon table data.')
        }

        let discountsResponse = await this.getRawPromotions();

        if (!discountsResponse.isSuccess) {
            return Response.error('Failed to retrieve raw discounts while compiling coupon table data.')
        }

        return Response.success(this.pairDiscountsAndCoupons(discountsResponse.value, couponsResponse.value));
    }


    async getRawCouponByCode(code: string): Promise<APIResponse<DE_Discount>> {
        let response = await APIPrepper.Get<DE_GetCouponResponse>(`coupons/codes/${code}`);
        return response.coupon ?
            Response.success(response.coupon) :
            Response.error("Error getting coupon");
    }

    pairDiscountsAndCoupons(discounts: DE_Discount[], coupons: DE_Coupon[]): TableCouponData[] {

        return coupons.map((coupon: DE_Coupon) => {
            let discount = discounts.find((possibleDiscount: DE_Discount) => possibleDiscount.id === coupon.data.discount_id);
            return {
                Id: coupon.id,
                Name: coupon.name,
                Type: (discount?.method ?? DiscountType.Unknown) as DiscountType,
                Priority: coupon.priority,
                Stacking: coupon.stacking,
                Active: coupon.active,
                Start: coupon.start_date !== "" ? dayjs(coupon.start_date) : null,
                End: coupon.expiration_date !== "" ? dayjs(coupon.expiration_date) : null,
                Codes: []
            } as TableCouponData
        });
    }

    async getCampaign(id: string): Promise<APIResponse<Campaign>> {
        let response = await APIPrepper.Get<DE_GetCampaignsResponse>('/campaigns');
        let discountResponse = await APIPrepper.Get<DE_GetDiscountsResponse>('discounts');
        let campaignDiscounts = [] as DE_Discount[];

        let allDiscounts = discountResponse.discounts;
        let campaign = response.campaigns.find((x: any) => x.id === id) as DE_Campaign;

        if (campaign && allDiscounts) {
            for (let i = 0; i < campaign.discount_ids.length; i++) {
                for (let x = 0; x < allDiscounts.length; x++) {
                    if (allDiscounts[x].id === campaign.discount_ids[i]) {
                        campaignDiscounts.push(allDiscounts[x]);
                    }
                }
            }
        }

        return Response.success(Convert.ToCampaign(campaign, campaignDiscounts));
    }

    async deleteCampaign(id: string) {
        APIPrepper.Delete(`campaigns/${id}`);
    }

    async deletePromo(id: string) {
        APIPrepper.Delete(`discounts/${id}`);
    }

    async deleteCoupon(id: string) {
        APIPrepper.Delete(`coupons/${id}`);
    }
}