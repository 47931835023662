import * as React from 'react';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Title from '../../../components/Title';
import { Typography } from '@mui/material';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Campaign, Discount } from '@onpurple/discount-engine-types';
import CampaignDetails from './CampaignDetails';
import CampaignDiscounts from './CampaignDiscounts';
import { useCampaigns } from '../../../helpers/apiHooks/useCampaigns';
import { WebsiteAlert } from '../../../components/Alerts';
import { AlertType } from "../../../data/Enums";
import { TableDiscountData } from '../../../data/Interfaces';
import { usePromotions } from '../../../helpers/apiHooks/usePromotions';

export default function CampaignView() {
  const apiHelper = useCampaigns();
  const apiDiscountHelper = usePromotions();
  const { id } = useParams();
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<TableDiscountData[]>([]);
  const [availableDiscounts, setAvailableDiscounts] = React.useState<Discount[]>([]);
  const [campaign, setCampaign] = React.useState<Campaign>();
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [modalActive, setModalActive] = React.useState<boolean>(false);
  let campaignDiscounts = [] as Discount[];
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: "",
    open: false
  } as WebsiteAlert)

  const handleModalClose = () => {
    setModalActive(false)
  }

  const handleModalActive = () => {
    setModalActive(true)
  }

  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleUpdateDiscounts = (ids: String[], campaign: Campaign) => {
    setSpinnerActive(true);
    campaignDiscounts = campaign.Discounts;

    for (let x = 0; x < ids.length; x++) {
      if (campaignDiscounts.length > 0) {
        for (let j = 0; j < campaignDiscounts?.length; j++) {
          if (ids[x] === campaignDiscounts[j].Id) {
            ids.splice(x, 1)
          }
        }
      }
      for (let i = 0; i < availableDiscounts.length; i++) {
        if (ids[x] === availableDiscounts[i].Id) {
          campaignDiscounts?.push(availableDiscounts[i])
          setHasChanges(true)
        }
      }
    }

    if (hasChanges) {
      setCampaign({ ...campaign, Discounts: campaignDiscounts })
      setSpinnerActive(false);
      handleModalClose();
    } else {
      setSpinnerActive(false);
      handleModalClose();
    }
  }

  const discountsToPromoTables = (discounts: Discount[]) => {
    return discounts.map(discount => {
      return {
        Id: discount.Id,
        Type: discount.DiscountType,
        Stacking: discount.Stacking,
        Active: discount.Active,
        Name: discount.Name,
        Start: discount.StartDate,
        End: discount.ExpirationDate,
        Priority: discount.Priority
      } as TableDiscountData;
    });
  }

  React.useEffect(() => {
    if (id) {
      setSpinnerActive(true);
      apiHelper.get(id)
        .then(response => {
          if (response.isSuccess) {
            setCampaign(response.data);
          } else {
            handleAlertOpen(AlertType.error, response.error);
          }
        })
        .catch(exception => {
          console.log(exception);
        });
      apiDiscountHelper.getAll()
        .then((response) => {
          if (response.isSuccess) {

            const filteredDiscounts = response.data?.filter(x => x.Deleted !== true)
            setTableData(discountsToPromoTables(filteredDiscounts ?? []) as TableDiscountData[]);
            setAvailableDiscounts(filteredDiscounts ?? []);
          }
        })
        .catch(exception => {
          console.log(exception);
          handleAlertOpen(AlertType.error, "Error calling API")
        })
        .finally(() => setSpinnerActive(false));
    }
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
      <Grid container spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ p: 3 }}>
            <Grid container>
              <Grid item xs>
                <Title>Site Campaigns</Title>
                <Typography color="text.secondary">Campaigns allow you to set groups of promotions to start and end together</Typography>
              </Grid>
              <Grid item xs={"auto"}>
                {/* <DiscountFilter type={DiscountFilterType.Campaigns} setFilters={handleSaveFilters} /> */}
              </Grid>
            </Grid>
          </Paper>
        </Box>

        {(campaign) ? <CampaignDetails campaign={campaign} handleUpdateDiscounts={handleUpdateDiscounts} tableData={tableData} handleModalClose={handleModalClose} handleModalActive={handleModalActive} setHasChanges={setHasChanges} modalActive={modalActive} hasChanges={hasChanges} /> : <></>}
        {(campaign) ? <CampaignDiscounts campaign={campaign} setHasChanges={setHasChanges} /> : <></>}
      </Grid>
      <LoadingSpinner active={spinnerActive} />
    </Container>
  );
}
