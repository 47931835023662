import { Alert, IconButton, Snackbar } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { AlertType } from "../data/Enums";

export interface WebsiteAlert {
  type: AlertType;
  message: string;
  open: boolean;
}

export default function CreateAlert(props: {
  alert: WebsiteAlert,
  handleClose(): void
}) {
  const { alert, handleClose } = props;

  return (
    <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <Alert
        severity={alert.type}
        variant='filled'
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {alert.message}
      </Alert>
    </Snackbar>
  )
}



