import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Title from '../components/Title';
import { Chip, Fab, Tooltip, Typography } from '@mui/material';
import TableFilter, { TableFilterType, SelectedFilters } from './components/TableFilter';
import LoadingSpinner from '../components/LoadingSpinner';
import CampaignTable from './Campaigns/CampaignTable';
import { TableCampaignData } from '../data/Interfaces';
import DeleteDialog from './components/DeleteDialog';
import Alerts, { WebsiteAlert } from '../components/Alerts';
import { AlertType } from "../data/Enums";
import AddIcon from '@mui/icons-material/Add';
import FilterHelper from '../helpers/FilterHelper';
import { useCampaigns } from '../helpers/apiHooks/useCampaigns';
import { useOktaAuth } from '@okta/okta-react';
import { RoleRequired, Roles } from '../helpers/RoleHelper';
import { Campaign } from '@onpurple/discount-engine-types';

const SelectedFiltersDisplay = (props: { filters: SelectedFilters[], handleRemove(filter: SelectedFilters): void }) => {

  return (props.filters.length === 0) ? (<></>) : (
    <Box sx={{ width: '100%', my: 1 }}>
      <Typography color="GrayText" sx={{ float: 'left', lineHeight: "200%", mr: 2 }}>Active Filters:</Typography> {props.filters.map(filter => <Chip sx={{ mr: 1 }} label={`${filter.name}: ${filter.value}`} onDelete={() => props.handleRemove(filter)} />)}
    </Box>
  )
}

const filterHelper = new FilterHelper();

export default function Campaigns() {
  const { oktaAuth, authState } = useOktaAuth();
  const apiHelper = useCampaigns();
  const [rows, setRows] = React.useState<TableCampaignData[]>([]);
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters[]>([]);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [deleteDialogActive, setDeleteDialogActive] = React.useState(false);
  const [deleteDialogId, setDeleteDialogId] = React.useState('');
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: "",
    open: false
  } as WebsiteAlert)

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleDeleteDialog = (id: string) => {
    setSpinnerActive(true);
    setDeleteDialogActive(false);

    apiHelper.delete(deleteDialogId).then(() => {
      setSpinnerActive(true);
      setRows(rows.filter(row => {
        return row.Id !== deleteDialogId;
      }))
      setSpinnerActive(false);
      handleAlertOpen(AlertType.success, 'Entry succesfully deleted');
    })
  }

  const handleCloseDialog = () => setDeleteDialogActive(false);

  const handleView = (id: string) => {
    window.location.replace(`/campaigns/${id}`);
  }

  const handleDelete = (id: string) => {
    setDeleteDialogId(id);
    setDeleteDialogActive(true);
  }

  const handleCloseAlert = () => {
    handleAlertClose();
  }

  const campaignsToCampaignTables = (campaigns: Campaign[]) => {
    return campaigns.map(campaign => {
      return {
        Id: campaign.Id,
        Active: campaign.Active,
        Name: campaign.Name,
        Start: campaign.StartDate,
        End: campaign.ExpirationDate,
        Priority: campaign.Priority,
        Discounts: campaign.Discounts
      } as TableCampaignData;
    });
  }

  const filterResults = () => {
    setSpinnerActive(true);
    apiHelper.getAll().then((rows) => {
      if (rows.isSuccess) {
        filterHelper.filterRows(
          selectedFilters,
          campaignsToCampaignTables(rows.data ?? [])
        ).then((filteredRows) => {
          setRows(filteredRows as TableCampaignData[]);
          setSpinnerActive(false);
        });
      }

    })
      .catch(exception => {
        console.log(exception);
        handleAlertOpen(AlertType.error, "Error calling API")
      })
      .finally(() => setSpinnerActive(false));
  }

  const handleSaveFilters = (filters: SelectedFilters[]) => setSelectedFilters(filters);

  const handleRemoveFilter = (filter: SelectedFilters) => {
    const remainingFilters = selectedFilters?.filter(value => value !== filter) ?? null;
    setSelectedFilters(remainingFilters);
  }

  React.useEffect(() => {
    filterResults();
  }, [selectedFilters]);

  return (
    <RoleRequired roles={[Roles.Viewer]}>
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <Grid container spacing={3}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ p: 3 }}>
              <Grid container>
                <Grid item xs>
                  <Title>Site Campaigns</Title>
                  <Typography color="text.secondary">Campaigns allow you to set groups of promotions to start and end together</Typography>
                </Grid>
                <Grid item xs={"auto"}>
                  <TableFilter type={TableFilterType.Campaigns} setFilters={handleSaveFilters} />
                </Grid>
              </Grid>
              <SelectedFiltersDisplay filters={selectedFilters} handleRemove={handleRemoveFilter} />
              <Alerts alert={websiteAlert} handleClose={handleCloseAlert} />
              <CampaignTable tableData={rows} handleView={handleView} handleDelete={handleDelete} />
            </Paper>
          </Box>
        </Grid>
        <LoadingSpinner active={spinnerActive} />
        <DeleteDialog id={deleteDialogId} active={deleteDialogActive} handleDelete={handleDeleteDialog} handleClose={handleCloseDialog} />
        <RoleRequired roles={[Roles.Editor]}>
          <Tooltip title="Add Campaign">
            <Fab
              color="primary"
              aria-label="add"
              href='campaigns/add'
              sx={{
                margin: 0,
                top: 'auto',
                right: 40,
                bottom: 30,
                left: 'auto',
                position: 'fixed',
              }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </RoleRequired>
      </Container>
    </RoleRequired>
  );
}
