import { Paper, Box, FormControlLabel, Grid, Stack, Switch, TextField, Button } from "@mui/material";
import React from "react";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { Campaign } from '@onpurple/discount-engine-types';
import Title from "../../../components/Title";
import CreateAlert, { WebsiteAlert } from "../../../components/Alerts";
import { AlertType } from "../../../data/Enums";
import DiscountsModal from "./Modal/DiscountsModal";
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useCampaigns } from "../../../helpers/apiHooks/useCampaigns";
import { TableDiscountData } from "../../../data/Interfaces";

export interface CampaignDetailsProps {
  campaign: Campaign,
  handleUpdateDiscounts(ids: String[], campaign: Campaign): void,
  tableData: TableDiscountData[],
  handleModalClose(): void,
  handleModalActive(): void,
  modalActive: boolean,
  hasChanges: boolean,
  setHasChanges(boolean: boolean): void
}

export default function CampaignDetails(props: CampaignDetailsProps) {
  const apiHelper = useCampaigns();
  const { campaign, handleUpdateDiscounts, tableData, handleModalClose, handleModalActive, modalActive, hasChanges, setHasChanges } = props;
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(campaign.Name);
  const [priority, setPriority] = React.useState<number>(campaign.Priority);
  const [active, setActive] = React.useState<boolean>(campaign.Active);
  const [startDate, setStartDate] = React.useState<Dayjs | undefined>(campaign.StartDate);
  const [expirationDate, setExpirationDate] = React.useState<Dayjs | undefined>(campaign.ExpirationDate);
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: '',
    open: false
  } as WebsiteAlert);

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setHasChanges(true);
  };

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriority(parseInt(event.target.value));
    setHasChanges(true);
  };

  const handleActiveChange = () => {
    setActive(!active);
    setHasChanges(true);
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date ?? undefined);
    setHasChanges(true);
  };

  const handleExpirationDateChange = (date: Dayjs | null) => {
    setExpirationDate(date ?? undefined);
    setHasChanges(true);
  };

  const handleSave = () => {
    if (!campaign) {
      return;
    }

    setSpinnerActive(true);
    campaign.Name = name;
    campaign.Priority = priority;
    campaign.Active = active;
    campaign.StartDate = startDate;
    campaign.ExpirationDate = expirationDate;

    apiHelper.update(campaign)
      .then(() => {
        setSpinnerActive(false);
        setHasChanges(false);
        handleAlertOpen(AlertType.success, "Campaign Updated");
      })
      .catch(error => {
        handleAlertOpen(AlertType.error, error);
        setSpinnerActive(false);
      })
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%', mt: 2 }}>
        <Paper sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs>
              <Title>Details</Title>
            </Grid>
            <Grid item xs="auto">
              <Button variant="contained" disabled={!hasChanges} onClick={handleSave}>Save Campaign Details</Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item xs={6}>
              <TextField fullWidth id="outlined-search" label="Name" value={name} onChange={handleNameChange} />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth type="number" id="outlined-search" label="Priority" value={priority} onChange={handlePriorityChange} />
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" spacing={2} sx={{ ml: 3, pt: 1 }}>
                <FormControlLabel control={<Switch checked={active} />} label="Active" onChange={handleActiveChange} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <MobileDatePicker
                inputFormat="MMMM DD, YYYY"
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField fullWidth id="start-date" {...params} />}
              />
            </Grid>
            <Grid item xs={6}>
              <MobileDatePicker
                inputFormat="MMMM DD, YYYY"
                label="Expiration Date"
                value={expirationDate}
                onChange={handleExpirationDateChange}
                renderInput={(params) => <TextField fullWidth id="expire-date" {...params} />}
              />
            </Grid>
            <Grid item xs="auto">
              <Button variant="contained" onClick={handleModalActive}>Add Discount</Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <DiscountsModal active={modalActive} handleClose={handleModalClose} campaign={campaign} handleUpdateDiscounts={handleUpdateDiscounts} tableData={tableData} />
      <LoadingSpinner active={spinnerActive} />
      <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
    </LocalizationProvider>
  )
}