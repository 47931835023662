import { Box, Chip, Typography } from "@mui/material"
import { SelectedFilters } from "./TableFilter"

export default function SelectedFiltersDisplay(props: { filters: SelectedFilters[], handleRemove(filter: SelectedFilters): void }) {

  return (props.filters.length === 0) ? (<></>) : (
    <Box sx={{ width: '100%', my: 1 }}>
      <Typography color="GrayText" sx={{ float: 'left', lineHeight: "200%", mr: 2 }}>Active Filters:</Typography>
      {props.filters.map(filter => <Chip sx={{ mr: 1 }} label={`${filter.name}: ${filter.value}`} onDelete={() => props.handleRemove(filter)} />)}
    </Box>
  )
}