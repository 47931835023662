
import * as React from 'react';
import { Box, Card, CardContent, Grid, InputAdornment, TableContainer, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Title from "../../../../components/Title";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Field } from '../../../../data/Field';
import { useProducts } from '../../../../helpers/apiHooks/useProducts';
import AvailableProductSelect from '../../AvailableProductSelect';
import { AvailableProduct, Discount, DiscountLogic, ProductMethod } from '@onpurple/discount-engine-types';

export default function ProductDiscountSection(props:
  {
    discount: Discount,
    handleUpdate(details: ProductMethod): void;
    handleErrors(error: string[]): any
  }) {
  const { discount, handleUpdate, handleErrors } = props;
  const productsApiHelper = useProducts();
  const groupMethod = discount.DiscountMethod as ProductMethod;
  const [selectedProduct, setSelectedProduct] = React.useState<AvailableProduct | null>(null);
  const [discountLogic, setDiscountLogic] = React.useState<DiscountLogic>(groupMethod.Logic);
  const [discountValue, setDiscountValue] = React.useState<Field<number>>(new Field("Discount Value", groupMethod.Value ?? 0));
  const [quantity, setQuantity] = React.useState<Field<number>>(new Field("Quantity", groupMethod.Quantity ?? 1));
  const [cartMinimum, setCartMinimum] = React.useState<Field<number>>(new Field("Cart Minimum", groupMethod.CartMinimum ?? 0));

  const updatePromotion = () => {
    handleUpdate({
      ...groupMethod,
      Logic: discountLogic,
      Value: (selectedProduct) ? discountValue.Value : 0,
      Quantity: selectedProduct ? quantity.Value : 0,
      CartMinimum: selectedProduct ? cartMinimum.Value : 0
    });
  }

  const handleSetSelectedProduct = (product: any) => {
    setSelectedProduct(product as AvailableProduct);
    updatePromotion();
  }

  const handleSetQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setQuantity(quantity.Update(value, value < 0 ? 'Quantity must be greater than 0' : ''));
    updatePromotion();
  }

  const handleSetCartMinimum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setCartMinimum(cartMinimum.Update(value, value < 0 ? 'Cart minimum must be greater than $0' : ''));
    updatePromotion();
  }

  const handleSetDiscountValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (discountLogic === 'absolute') {
      const value = parseFloat(event.target.value);
      setDiscountValue(discountValue.Update(value, (selectedProduct && value > selectedProduct.Price) ? 'Discount value cannot be more than product value' : ''));
    } else {
      const value = parseInt(event.target.value);
      setDiscountValue(discountValue.Update(value, (value > 100) ? 'Discount value cannot be more than 100%' : ''));
    }

    updatePromotion();
  };

  const handleSetDiscountLogic = (
    event: React.MouseEvent<HTMLElement>,
    newDiscountLogic: string | null
  ) => {
    setDiscountLogic((newDiscountLogic === 'absolute') ? DiscountLogic.Absolute : DiscountLogic.Relative);
    updatePromotion();
  }

  const hasError = (field: Field<any>) => {
    return !field.Error ? false : field.Error !== "";
  }


  React.useEffect(() => {
    let errors = [] as string[];

    if (discountValue.Error && discountValue.Error !== "") {
      errors.push(discountValue.Error);
    }

    if (quantity.Error && quantity.Error !== "") {
      errors.push(quantity.Error);
    }

    if (cartMinimum.Error && cartMinimum.Error !== "") {
      errors.push(cartMinimum.Error);
    }

    handleErrors(errors);
  }, [discountValue, quantity, cartMinimum])

  const getDiscountLogicSelectProps = () => {
    return discountLogic === 'absolute' ? {
      startAdornment: <InputAdornment position="start">$</InputAdornment>
    } : {
      endAdornment: <InputAdornment position="end">%</InputAdornment>
    }
  }

  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Title>Product Discount Details</Title>
      </Grid>
      <Grid item xs={7}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AvailableProductSelect
              label="Select Product"
              multiple={false}
              handleSelected={handleSetSelectedProduct}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth type="number"
              id="outlined-search"
              label={discountValue?.Title}
              InputProps={getDiscountLogicSelectProps()}
              value={discountValue?.Value}
              onChange={handleSetDiscountValue}
              error={discountValue ? hasError(discountValue) : false}
              helperText={discountValue?.Error}
              disabled={selectedProduct === null} />
          </Grid>
          <Grid item xs={5}>
            <ToggleButtonGroup
              color="primary"
              value={discountLogic}
              exclusive
              onChange={handleSetDiscountLogic}
            >
              <ToggleButton value={DiscountLogic.Absolute}>Absolute</ToggleButton>
              <ToggleButton value={DiscountLogic.Relative}>Relative</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth type="number"
              id="outlined-search"
              label="Quantity"
              value={quantity.Value}
              error={hasError(quantity)}
              helperText={quantity?.Error}
              onChange={handleSetQuantity}
              disabled={selectedProduct === null} />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth type="number"
              id="outlined-search"
              label="Cart Minimum"
              value={cartMinimum.Value}
              error={hasError(cartMinimum)}
              helperText={cartMinimum?.Error}
              onChange={handleSetCartMinimum}
              disabled={selectedProduct === null}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }} />
          </Grid>
        </Grid>



      </Grid>
      <Grid item xs={5}>
        <Box sx={{ minWidth: 275 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Discount Details
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                        <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                          Selected Product:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                        <Typography color="text.primary">
                          {selectedProduct?.Name}
                        </Typography>
                      </TableCell>
                    </TableRow>


                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                        <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                          Product Category:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                        <Typography color="text.primary">
                          {selectedProduct?.Category}
                        </Typography>
                      </TableCell>
                    </TableRow>


                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                        <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                          Product Sku:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                        <Typography color="text.primary">
                          {selectedProduct?.Sku}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                        <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                          Size:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                        <Typography color="text.primary">
                          {selectedProduct?.Variation}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                        <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                          Price:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                        <Typography color="text.primary">
                          ${selectedProduct?.Price.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                        <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                          Discounted Price:
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                        <Typography color="text.primary">
                          ${selectedProduct?.Price.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>


                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}



