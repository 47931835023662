/* eslint-disable no-loop-func */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Campaign } from '@onpurple/discount-engine-types';
import { Button, Grid, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Dayjs } from "dayjs";
import LoadingSpinner from '../../../../components/LoadingSpinner';
import CreateAlert, { WebsiteAlert } from "../../../../components/Alerts";
import { AlertType } from "../../../../data/Enums";

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  position: 'absolute',
  top: '20%',
  left: '35%',
  right: '35%',
  overflow: 'scroll',
  height: '40%'
};

export default function DateModal(props: {
  active: boolean,
  handleClose(): void,
  campaign: Campaign,
  selectedIds: string[],
  setHasChanges(boolean: boolean): void
}) {
  const { active, handleClose, selectedIds, setHasChanges } = props;
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [expirationDate, setExpirationDate] = React.useState<Dayjs | null>(null);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: '',
    open: false
  } as WebsiteAlert);

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
  };

  const handleExpirationDateChange = (date: Dayjs | null) => {
    setExpirationDate(date);
  };

  const handleSaveDate = () => {
    function saveNewDates() {
      let campaignDiscounts = props.campaign.Discounts;

      return new Promise<void>((resolve) => {
        setSpinnerActive(true);
        for (let i = 0; i < selectedIds.length; i++) {
          for (let x = 0; x < campaignDiscounts.length; x++) {
            if (selectedIds[i] === campaignDiscounts[x].Id) {
              campaignDiscounts[x].StartDate = startDate;
              campaignDiscounts[x].ExpirationDate = expirationDate
              setHasChanges(true);
              setSpinnerActive(false);
              handleClose();
            }
          }
        }
        resolve();
      })
    }

    saveNewDates();
  }

  React.useEffect(() => {

  }, []);

  return (
    <div>
      <Modal
        open={active}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change dates for {selectedIds.length} discounts
          </Typography>
          <Grid item xs={8} mt={2}>
            <MobileDatePicker
              inputFormat="MMMM DD, YYYY"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField fullWidth id="start-date" {...params} />}
            />
          </Grid>
          <Grid item xs={8} mt={2}>
            <MobileDatePicker
              inputFormat="MMMM DD, YYYY"
              label="Expire Date"
              value={expirationDate}
              onChange={handleExpirationDateChange}
              renderInput={(params) => <TextField fullWidth id="expire-date" {...params} />}
            />
          </Grid>
          <Grid item xs="auto" mt={2}>
            <Button variant="contained" onClick={handleSaveDate}>Update Dates</Button>
          </Grid>
        </Box>
      </Modal>
      <LoadingSpinner active={spinnerActive} />
      <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
    </div>
  );
}