import { Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import { PurpleApp, PurpleMenuItem, PurpleNotification, RequiredAuth } from '@onpurple/purple-okta-admin'
import './App.css';

import {
  CalendarMonth as CalendarMonthIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Dashboard as DashboardIcon,
  Discount as DiscountIcon,
} from '@mui/icons-material';

import Promos from "./pages/Promos";
import Coupons from "./pages/Coupons";
import Campaigns from "./pages/Campaigns";
import PromoView from "./pages/PromoView/PromoView";
import CampaignView from "./pages/Campaigns/CampaignView/CampaignView";
import PromosAdd from "./pages/PromoAdd/PromoAdd";
import CampaignAdd from "./pages/Campaigns/CampaignAdd";
import Profile from "./pages/Profile";
import { Roles } from "./helpers/RoleHelper";

const notifications = [{
  id: "1",
  title: "Main notification title",
  subTitle: "Subtitle here",
  unread: true,
  date: new Date()
}, {
  id: "2",
  title: "Main notification title",
  subTitle: "Subtitle here",
  unread: false,
  date: new Date()
}, {
  id: "3",
  title: "Main notification title",
  subTitle: "Subtitle here",
  unread: false,
  date: new Date()
}] as PurpleNotification[]

const menuItems = [{
  title: "Dashboard",
  link: "/",
  icon: <DashboardIcon />
}, {
  title: "Promotions",
  link: "/promos",
  icon: <DiscountIcon />,
  roles: [Roles.Viewer]
}, {
  title: "Coupons",
  link: "/coupons",
  icon: <ConfirmationNumberIcon />
}, {
  title: "Campaigns",
  link: "/campaigns",
  icon: <CalendarMonthIcon />
}
] as PurpleMenuItem[];


function App() {

  return (
    <PurpleApp menuItems={menuItems} notifications={notifications}>
      <Route path="/" element={<Dashboard />} />
      <Route path='/' element={<RequiredAuth />}>
        <Route path="/promos" element={<Promos />} />
        <Route path="/promos/add" element={<PromosAdd />} />
        <Route path="/promos/:id" element={<PromoView />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/campaigns/add" element={<CampaignAdd />} />
        <Route path="/campaigns/:id" element={<CampaignView />} />
        <Route path="/profile" element={<Profile />} />
      </Route>
    </PurpleApp>
  )
}

export default App;