import { BuyMoreSaveMoreMethod, CreditMethod, Discount, DiscountType, GiftItemMethod, ProductGroupMethod, ProductMethod } from '@onpurple/discount-engine-types';
import BuyMoreSaveMoreSection from "./BuyMoreSaveMoreSection/BuyMoreSaveMoreSection";
import CreditSection from "./CreditSection/CreditSection";
import GiftItemSection from "./GiftItemSection/GiftItemSection";
import ProductDiscountSection from "./ProductDiscountSection/ProductDiscountSection";
import ProductGroupSection from "./ProductGroupSection/ProductGroupSection";

export interface DiscountDetailsProps {
  discount: Discount | null;
  handleUpdate(discount: Discount): void;
  handleErrors(error: string[]): any
}

export default function DiscountDetails(props: DiscountDetailsProps) {
  const {
    discount,
    handleUpdate,
    handleErrors } = props;

  const handleUpdateProduct = (details: ProductMethod) => {
    handleUpdate({ ...discount, DiscountMethod: details } as Discount);
  }

  const handleUpdateGift = (details: GiftItemMethod) => {
    handleUpdate({ ...discount, DiscountMethod: details } as Discount);
  }

  const handleUpdateBMSM = (details: BuyMoreSaveMoreMethod) => {
    handleUpdate({ ...discount, DiscountMethod: details } as Discount);
  }

  const handleUpdateCredit = (details: CreditMethod) => {
    handleUpdate({ ...discount, DiscountMethod: details } as Discount);
  }

  const handleUpdateProductGroup = (details: ProductGroupMethod) => {
    handleUpdate({ ...discount, DiscountMethod: details } as Discount);
  }


  return (
    <>
      {(discount?.DiscountType === DiscountType.Product) ? <ProductDiscountSection discount={discount} handleUpdate={handleUpdateProduct} handleErrors={handleErrors} /> : <></>}
      {(discount?.DiscountType === DiscountType.GiftItem) ? <GiftItemSection discount={discount} handleUpdate={handleUpdateGift} handleErrors={handleErrors} /> : <></>}
      {(discount?.DiscountType === DiscountType.BuyMoreSaveMore && discount.DiscountMethod) ? <BuyMoreSaveMoreSection discount={discount} handleUpdate={handleUpdateBMSM} handleErrors={handleErrors} /> : <></>}
      {(discount?.DiscountType === DiscountType.Credit) ? <CreditSection discount={discount} handleUpdate={handleUpdateCredit} handleErrors={handleErrors} /> : <></>}
      {(discount?.DiscountType === DiscountType.ProductGroup) ? <ProductGroupSection discount={discount} handleUpdate={handleUpdateProductGroup} handleErrors={handleErrors} /> : <></>}
    </>
  )
}