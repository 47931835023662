import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TableDiscountData } from '../../../../data/Interfaces';
import { Campaign } from '@onpurple/discount-engine-types';
import DiscountModalTable from './DiscountsModalTable';

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  position: 'absolute',
  top: '10%',
  left: '30%',
  right: '30%',
  overflow: 'scroll',
  height: '80%'
};

export default function DiscountsModal(props: {
  active: boolean,
  handleClose(): void,
  campaign: Campaign,
  handleUpdateDiscounts(ids: String[], campaign: Campaign): void,
  tableData: TableDiscountData[]
}) {
  const { active, handleClose, handleUpdateDiscounts, tableData } = props;
  const [rows, setRows] = React.useState<TableDiscountData[]>([]);

  React.useEffect(() => {
    setRows(tableData);
  }, []);

  return (
    <div>
      <Modal
        open={active}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            All Promotions
          </Typography>
          <DiscountModalTable tableData={tableData} campaign={props.campaign} handleUpdateDiscounts={handleUpdateDiscounts} />
        </Box>
      </Modal>
    </div>
  );
}