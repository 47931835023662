import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Title from '../components/Title';
import { Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';


export default function Profile() {
  const { authState } = useOktaAuth();

  console.log(authState?.accessToken?.claims.admin);
  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
      <Grid container spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ p: 3 }}>

            <Grid container>
              <Grid item xs>
                <Title>User Profile</Title>
                <Typography color="text.secondary">Site coupons are discounts used on the website when enterning a promo code during checkout</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                'word-wrap': 'break-word'
              }}>
                <pre>{JSON.stringify(authState?.accessToken?.claims)}</pre>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </Container>
  );
}
