// THIS SHOULD NO LONGER BE USED AND IS DEPRECATED. PLEASE USE ONE OF THE FOLLOWING INTERFACES

// - src/data/_CommonEnums.ts
// - src/data/AvailableProduct.ts
// - src/data/Coupon.ts
// - src/data/Discount.ts
// - src/data/Tag.ts
// - src/data/Campaign.ts



import { Campaign, Discount, DiscountTag } from "./Interfaces";
import dayjs, { Dayjs } from "dayjs";

export enum DE_Discount_Method {
  Product = "product",
  Gift = "gift",
  Buy_More_Save_More = "buy_more_save_more",
  Bundle = "bundle",
  Credit = "credit",
  Coupon = "coupon",
}

export enum DE_Discount_Type {
  Absolute = "absolute",
  Relative = "relative",
}

export enum DE_Bmsm_Variant {
  Products = "products",
  Cart_Value = "cart_value",
}

export interface DE_Tag {
  text: string;
}

export interface DE_Base_Discount {
  id?: string;
  active: boolean;
  deleted: boolean;
  use_engine_skus: boolean;
  name: string;
  display_name: string;
  description: string;
  tags: string[];
  stacking: boolean;
  priority: number;
  start_date: string | undefined;
  expiration_date: string | undefined;
  create_date: string | undefined;
  method?: DE_Discount_Method;
  multi_apply_limit?: number;
}
export interface Product_Details {
  sku: string;
  type: DE_Discount_Type;
  quantity: number;
  value: number;
  cart_minimum: number;
}

export interface DE_Gift_Details {
  sku: string;
  quantity: number;
  default_gift: string;
  gift_selection: string[];
}

export interface DE_Bundle_Group {
  id: string;
  display_name: string;
  skus: string[];
  value: number;
  type: DE_Discount_Type;
}

export interface DE_Bundle_Details {
  use_or_logic?: boolean;
  not_discounted: DE_Bundle_Group[];
  discounted: DE_Bundle_Group[];
  cart_minimum: number;
}

export interface DE_Credit_Details {
  discounted: DE_Bundle_Group;
  not_discounted: DE_Bundle_Group;
  cart_minimum: number;
}

export interface DE_Increment_Value {
  priority: number;
  display_name: string;
  quantity: number;
  cart_value: number;
  value: number;
  type: DE_Discount_Type;
  removed: boolean;
}


export interface DE_Buy_More_Save_More_Details {
  skus: string[];
  variant: DE_Bmsm_Variant;
  increments: DE_Increment_Value[];
  cart_minimum: number;
}
export interface DE_Coupon_Data {
  discount_id: string;
}

export interface DE_Discount extends DE_Base_Discount {
  data:
  | Product_Details
  | DE_Gift_Details
  | DE_Bundle_Details
  | DE_Credit_Details
  | DE_Buy_More_Save_More_Details;
}

export interface DE_Coupon extends DE_Base_Discount {
  data: DE_Coupon_Data;
}

export interface DE_Campaign {
  id: string;
  name: string;
  active: boolean;
  priority: number;
  start_date: string | undefined;
  expiration_date: string | undefined;
  discount_ids: string[];
}

export interface DE_Api_Response {
  success: boolean;
  error: string;
}

export interface DE_GetDiscountsResponse extends DE_Api_Response {
  discounts: DE_Discount[];
}
export interface DE_GetCouponsResponse extends DE_Api_Response {
  coupons: DE_Coupon[];
}
export interface DE_GetCouponResponse extends DE_Api_Response {
  coupon: DE_Discount;
}

export interface DE_PostTagResponse extends DE_Api_Response {
  tag: DE_Tag
}

export interface DE_GetTagsResponse extends DE_Api_Response {
  tags: DE_Tag[]
}

export interface DE_GetCampaignsResponse extends DE_Api_Response {
  campaigns: DE_Campaign[]
}

export interface DE_UpdateCampaignResponse extends DE_Api_Response {
  campaign: DE_Campaign
}

export interface DE_PostCampaignResponse extends DE_Api_Response {
  campaigns: DE_Campaign[]
}

export interface DE_UpdateDiscountResponse extends DE_Api_Response {
  discount: DE_Discount
}

export interface DE_PostDiscountResponse extends DE_Api_Response {
  discounts: DE_Discount[]
}


export class DE_Convert {

  static UpdateDiscount(de_discount: DE_Discount, discount: Discount) {
    return {
      ...de_discount,
      name: discount.Name,
      display_name: discount.DisplayName,
      description: discount.Description,
      priority: discount.Priority,
      stacking: discount.Stacking,
      active: discount.Active,
      start_date: discount.StartDate?.toString() ?? "",
      expiration_date: discount.ExpirationDate?.toString() ?? "",
      multi_apply_limit: discount.MultiApplyLimit,
      tags: de_discount.tags
    } as DE_Discount;
  }

  static ToDiscount(de_discount: DE_Discount) {
    return {
      Name: de_discount.name,
      DisplayName: de_discount.display_name,
      Description: de_discount.description,
      Priority: de_discount.priority,
      Stacking: de_discount.stacking,
      Active: de_discount.active,
      StartDate: dayjs(de_discount.start_date),
      ExpirationDate: dayjs(de_discount.expiration_date),
      Tags: de_discount.tags,
    } as Discount
  }

  static ToDiscounts(de_discounts: DE_Discount[]) {
    return de_discounts.map((Discount: DE_Discount) => {
      return DE_Convert.ToDiscount(Discount)
    })
  }

  static ToCampaign(campaign: Campaign) {
    return {
      name: campaign.Name,
      priority: campaign.Priority,
      active: campaign.Active,
      start_date: campaign.StartDate?.toString(),
      expiration_date: campaign.ExpirationDate?.toString(),
      discount_ids: campaign.Discounts.map(discount => {
        return discount.Id
      })
    } as DE_Campaign;
  }
  

  static UpdateCampaign(de_campaign: DE_Campaign, campaign: Campaign) {
    let campaignDiscountIds = [];
    for (let i = 0; i < campaign.Discounts.length; i++) {
      campaignDiscountIds.push(campaign.Discounts[i].Id)
    }
    return {
      ...de_campaign,
      name: campaign.Name,
      priority: campaign.Priority,
      active: campaign.Active,
      start_date: campaign.StartDate?.toString(),
      expiration_date: campaign.ExpirationDate?.toString(),
      discount_ids: campaignDiscountIds
    } as DE_Campaign;
  }

  static ToTag(tag: DE_Tag) {
    return {
      Text: tag.text
    } as DiscountTag;
  }

  static ToTags(tags: DE_Tag[]) {
    return tags.map(x => this.ToTag(x));
  }

}