// THIS SHOULD NO LONGER BE USED AND IS DEPRECATED. PLEASE USE ONE OF THE FOLLOWING INTERFACES

// - src/data/_CommonEnums.ts
// - src/data/AvailableProduct.ts
// - src/data/Coupon.ts
// - src/data/Discount.ts
// - src/data/Tag.ts
// - src/data/Campaign.ts

import dayjs, { Dayjs } from "dayjs";
import { DE_Bmsm_Variant, DE_Bundle_Details, DE_Bundle_Group, DE_Buy_More_Save_More_Details, DE_Campaign, DE_Credit_Details, DE_Discount, DE_Discount_Method, DE_Discount_Type, DE_Gift_Details, DE_Increment_Value, DE_Tag, Product_Details } from "./Dtos";
import { BMSMVariant, DiscountLogic, DiscountType, AndOrLogic } from "./Enums";
import { TokenResponse } from '@okta/okta-auth-js'


export interface AvailableProduct {
    Id: string,
    Name: string,
    Categories: string[],
    Category: string,
    Variation: string,
    Sku: string,
    Price: number,
    DiscountEngineSku: string
}

export interface DiscountTag {
    Text: string,
}

export interface ProductGroupMethod {
    DiscountedProducts: ProductGroup[],
    NotDiscounted: string[],
    CartMinimum: number,
    AndOrLogic: AndOrLogic
}

export interface CreditMethod {
    Id: string,
    CartMinimum: number,
    Discounted: string[],
    NotDiscounted: string[]
}

export interface BaseDiscount {
    Id: string,
    Name: string,
    DisplayName: string,
    Description: string,
    Priority: number | null,
    Stacking: boolean,
    Active: boolean,
    StartDate: Dayjs | null,
    ExpirationDate: Dayjs | null,
    MultiApplyLimit: number | null,
    Tags: string[],
    LastModifiedDate?: Dayjs,
    LastModifiedBy?: string
    DiscountType: DiscountType
}
export interface Discount extends BaseDiscount {
    DiscountMethod?: ProductGroupMethod | ProductMethod | GiftItemMethod | BuyMoreSaveMoreMethod | CreditMethod,
}

export interface Campaign {
    Id: string,
    Name: string,
    Priority: number,
    Active: boolean,
    StartDate: Dayjs | null,
    ExpirationDate: Dayjs | null,
    Discounts: Discount[]
}

export interface User {
    id: string,
    name: string
}

export interface AuthContextType {
    user: string | null;
    signin: (user: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}
export interface RowData {
    Id: string,
    Active: boolean,
    Name: string,
    Start: Dayjs | null,
    End: Dayjs | null,
    Priority: number
}
export interface TableDiscountData extends RowData {
    Type: DiscountType,
    Stacking: boolean
}

export interface TableCouponData extends RowData {
    Type: DiscountType,
    Stacking: boolean,
    Codes: string[]
}
export interface TableCampaignData extends RowData {

}

export interface ProductGroup {
    Id: string,
    DisplayName: string,
    Logic: DiscountLogic,
    Value: number
    Products: AvailableProduct[]
}


export interface BMSMIncrement {
    Quantity: number,
    CartValue: number,
    Removed: boolean,
    Priority: number,
    DisplayName: string,
    Value: number,
    Logic: DiscountLogic
}


export interface ProductMethod {
    Id: string,
    ProductId: string,
    Quantity: number,
    Value: number,
    Logic: DiscountLogic,
    CartMinimum: number,
}

export interface GiftItemMethod {
    Id: string,
    ProductId: string,
    ProductQuantity: number,
    FreeProductIds: string[]
}

export interface BuyMoreSaveMoreMethod {
    Variant: BMSMVariant,
    CartMinimum: number,
    Products: string[],
    Increments: BMSMIncrement[]
}

export interface APIResponse<A> {
    isSuccess: boolean,
    error: string
    value: A
}

export class Response {
    static success<T>(value?: T) {
        return { isSuccess: true, value: value } as APIResponse<T>;
    }

    static error<T>(message: string) {
        console.log(message);
        return { isSuccess: false, error: message } as APIResponse<T>;
    }

    static {
        // console.log('Class static initialization block called');
    }
}

export class Convert {

    static ToDiscountType(method: DE_Discount_Method | undefined): DiscountType {
        switch (method) {
            case DE_Discount_Method.Bundle:
                return DiscountType.ProductGroup;
            case DE_Discount_Method.Buy_More_Save_More:
                return DiscountType.BuyMoreSaveMore;
            case DE_Discount_Method.Coupon:
                return DiscountType.Unknown
            case DE_Discount_Method.Credit:
                return DiscountType.Credit;
            case DE_Discount_Method.Gift:
                return DiscountType.GiftItem;
            case DE_Discount_Method.Product:
                return DiscountType.Product;
            default:
                return DiscountType.Unknown
        }
    }

    static ToBMSMVariant(variant: DE_Bmsm_Variant): BMSMVariant {
        switch (variant) {
            case DE_Bmsm_Variant.Cart_Value:
                return BMSMVariant.CartValue;
            case DE_Bmsm_Variant.Products:
                return BMSMVariant.Products;
            default:
                return BMSMVariant.Unknown;
        }
    }

    static ToDiscountLogic(type: DE_Discount_Type): DiscountLogic {
        switch (type) {
            case DE_Discount_Type.Absolute:
                return DiscountLogic.absolute;
            case DE_Discount_Type.Relative:
                return DiscountLogic.relative;
            default:
                return DiscountLogic.Unknown;
        }
    }

    static ToBMSMIncrement(increment: DE_Increment_Value): BMSMIncrement {
        return {
            Quantity: increment.quantity,
            CartValue: increment.cart_value,
            Removed: increment.removed,
            Priority: increment.priority,
            DisplayName: increment.display_name,
            Value: increment.value,
            Logic: Convert.ToDiscountLogic(increment.type)
        } as BMSMIncrement;
    }

    static ToBMSMIncrements(increments: DE_Increment_Value[]): BMSMIncrement[] {
        return increments.map(x => Convert.ToBMSMIncrement(x));
    }

    static ToBuyMoreSaveMoreMethod(data: DE_Buy_More_Save_More_Details): BuyMoreSaveMoreMethod {
        return {
            Variant: Convert.ToBMSMVariant(data.variant),
            CartMinimum: data.cart_minimum,
            Products: data.skus,
            Increments: Convert.ToBMSMIncrements(data.increments)
        } as BuyMoreSaveMoreMethod;
    }

    static DEBundleGroupToDiscountedProducts(groups: DE_Bundle_Group[]) {
        return groups.map(group => {
            return {
                Id: group.id,
                DisplayName: group.display_name,
                Logic: DiscountLogic.Unknown,
                Value: group.value,
                // Products: group.skus.map
            } as ProductGroup;
        });
    }

    // id: string;
    // display_name: string;
    // skus: string[];
    // value: number;
    // type: DE_Discount_Type;

    static ToProductGroupMethod(data: DE_Bundle_Details): ProductGroupMethod {
        return {
            // NotDiscounted: data.not_discounted,
            DiscountedProducts: this.DEBundleGroupToDiscountedProducts(data.discounted),
            CartMinimum: 1,
            AndOrLogic: AndOrLogic.and
        } as ProductGroupMethod;
    }

    static ToDiscountMethod(method: DE_Discount_Method | undefined, data: Product_Details
        | DE_Gift_Details
        | DE_Bundle_Details
        | DE_Credit_Details
        | DE_Buy_More_Save_More_Details): ProductGroupMethod | ProductMethod | GiftItemMethod | BuyMoreSaveMoreMethod | CreditMethod | null {
        switch (method) {
            case DE_Discount_Method.Bundle:
                return Convert.ToProductGroupMethod(data as DE_Bundle_Details);
            case DE_Discount_Method.Buy_More_Save_More:
                return Convert.ToBuyMoreSaveMoreMethod(data as DE_Buy_More_Save_More_Details);
            case DE_Discount_Method.Coupon:
                return null;
            case DE_Discount_Method.Credit:
                return null;
            case DE_Discount_Method.Gift:
                return null;
            case DE_Discount_Method.Product:
                return null;;
            default:
                return null;
        }
    }

    static ToDiscount(discount: DE_Discount) {
        return {
            Id: discount.id ?? "",
            Name: discount.name,
            DisplayName: discount.display_name,
            Description: discount.description,
            Priority: discount.priority,
            Stacking: discount.stacking,
            Active: discount.active,
            StartDate: (discount.start_date && discount.start_date !== "") ? dayjs(discount.start_date) : null,
            ExpirationDate: (discount.start_date && discount.expiration_date !== "") ? dayjs(discount.expiration_date) : null,
            MultiApplyLimit: discount.multi_apply_limit ?? 0,
            Tags: discount.tags,
            DiscountType: this.ToDiscountType(discount.method),
            DiscountMethod: this.ToDiscountMethod(discount.method, discount.data),
        } as Discount;
    }

    static ToDiscounts(discounts: DE_Discount[]): Discount[] {
        return discounts.map(discount => this.ToDiscount(discount));
    }

    static ToTableDiscount(discount: DE_Discount) {
        return {
            Id: discount.id ?? "",
            Name: discount.name,
            Type: this.ToDiscountType(discount.method),
            Priority: discount.priority,
            Stacking: discount.stacking,
            Active: discount.active,
            Start: dayjs(discount.start_date),
            End: dayjs(discount.expiration_date),
        } as TableDiscountData;
    }

    static ToTableCampaign(campaign: DE_Campaign) {
        return {
            Id: campaign.id ?? "",
            Name: campaign.name,
            Priority: campaign.priority,
            Active: campaign.active,
            Start: dayjs(campaign.start_date),
            End: dayjs(campaign.expiration_date),
        } as TableCampaignData;
    }

    static ToTableDiscounts(discounts: DE_Discount[]) {
        return discounts.map(discount => this.ToTableDiscount(discount));
    }

    static ToTableCampaigns(campaigns: DE_Campaign[]) {
        return campaigns.map(campaign => this.ToTableCampaign(campaign));
    }

    static ToCampaign(campaign: DE_Campaign, discounts: DE_Discount[]) {
        return {
            Id: campaign.id,
            Name: campaign.name,
            Priority: campaign.priority,
            Active: campaign.active,
            StartDate: dayjs(campaign.start_date),
            ExpirationDate: dayjs(campaign.expiration_date),
            Discounts: this.ToDiscounts(discounts)
        } as Campaign;
    }

    static AddDiscountsToCampaign(campaign: Campaign, discounts: Discount[]) {
        return {
            ...campaign,
            Discounts: discounts
        } as Campaign
    }

    static DiscountToPromoTable(discount: Discount) {
        return {
            Id: discount.Id,
            Type: discount.DiscountType,
            Stacking: discount.Stacking,
            Active: discount.Active,
            Name: discount.Name,
            Start: discount.StartDate,
            End: discount.ExpirationDate,
            Priority: discount.Priority
        } as TableDiscountData;
    }

    static DiscountsToPromoTables(discounts: Discount[]) {
        return discounts.map(discount => this.DiscountToPromoTable(discount));
    }
}