import * as React from 'react';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Title from '../../components/Title';
import { Button, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Popover, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner';
import PromoViewCommonFields from '../components/Discounts/DiscountCommonFields';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateAlert, { WebsiteAlert } from '../../components/Alerts';
import DiscountDetails from '../components/Discounts/DiscountDetails';
import { usePromotions } from '../../helpers/apiHooks/usePromotions';
import { useTags } from '../../helpers/apiHooks/useTags';
import { useNavigate } from 'react-router-dom';
import { BuyMoreSaveMoreMethod, CreditMethod, Discount, DiscountType, GiftItemMethod, ProductGroupMethod, ProductMethod, Tag } from '@onpurple/discount-engine-types';
import { AlertType } from '../../data/Enums';


const AddButton = (props: { disabled: boolean, errors: string[], savePromotion(): void }) => {
  const { disabled, savePromotion, errors = [] } = props;
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);

  const handleErrorPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleCloseErrorPopover = () => {
    setPopoverAnchor(null);
  };

  const popoverOpen = Boolean(popoverAnchor);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  return errors.length === 0 ? (
    <Button variant="contained" disabled={disabled} onClick={savePromotion}>Add</Button>
  ) : (
    <>
      <Tooltip title="Save promotion">
        <Button variant="contained" aria-describedby={popoverId} color="error" onClick={handleErrorPopover}>Add</Button>
      </Tooltip>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={popoverAnchor}
        onClose={handleCloseErrorPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List dense={false}>
          {errors.map((error, index) => (
            <>
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  primary={(<Typography sx={{ fontSize: 15, color: 'red' }}>{error}</Typography>)}
                // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
              {(index !== errors.length - 1) ? <Divider component="li" /> : <></>}
            </>
          ))}
        </List>
      </Popover>
    </>
  );
}

export default function PromosAdd() {
  const navigate = useNavigate();
  const promoApiHelper = usePromotions();
  const tagApiHelper = useTags();
  const [discount, setDiscount] = React.useState<Discount | null>(null);
  const [selectedDiscountType, setSelectedDiscountType] = React.useState<DiscountType>(DiscountType.Unknown);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [changesAvailable, setChangesAvailable] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [newTags, setNewTags] = React.useState<Tag[]>([]);
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: "",
    open: false
  } as WebsiteAlert)

  const handleChangeDiscountType = (event: SelectChangeEvent) => {

    let add = DiscountType.Unknown;
    let method: ProductMethod | GiftItemMethod | BuyMoreSaveMoreMethod | ProductGroupMethod | CreditMethod | undefined;

    switch (event.target.value as string) {
      case "product":
        add = DiscountType.Product;
        method = {} as ProductMethod;
        break;

      case "gift":
        add = DiscountType.GiftItem;
        method = {} as GiftItemMethod;
        break;

      case "buy_more_save_more":
        add = DiscountType.BuyMoreSaveMore;
        method = {} as BuyMoreSaveMoreMethod;
        break;

      case "bundle":
        add = DiscountType.ProductGroup;
        method = {} as ProductGroupMethod;
        break;

      case "coupon":
        add = DiscountType.Credit;
        method = {} as CreditMethod;
        break;
    }

    setSelectedDiscountType(add);
    setDiscount(discount ? { ...discount, DiscountType: add, DiscountMethod: method } : { DiscountType: add, DiscountMethod: method } as Discount);
  }
  let params = useParams();
  const promoId = params.id;

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleUpdate = (discount: Discount) => {
    setDiscount(discount);
    setChangesAvailable(true);
  }

  const handleAddNewTag = (tag: Tag) => setNewTags([...newTags, tag]);

  const handleErrors = (errors: string[]) => {
    setErrors(errors);
  }

  const handleAddPromotion = () => {
    // Turn on spinner, save, remove spinner, show success popup in corner
    if (!discount) {
      return;
    }

    setSpinnerActive(true);

    promoApiHelper.add(discount)
      .then(response => {
        if (response.isSuccess) {

          newTags.forEach(tag => tagApiHelper.add(tag.Text));

          setSpinnerActive(false);
          setChangesAvailable(false);
          navigate("/promos");
        } else {
          handleAlertOpen(AlertType.error, response.error);
        }
      })
      .catch(error => {
        handleAlertOpen(AlertType.error, error.message)
      })
      .finally(() => setSpinnerActive(false))
  }

  React.useEffect(() => {
    setDiscount({
      Deleted: false,
      CreateDate: null,
      UseDiscountEngineSku: false,
      Id: "",
      Name: "",
      DisplayName: "",
      Description: "",
      Priority: null,
      Stacking: false,
      Active: false,
      StartDate: null,
      ExpirationDate: null,
      MultiApplyLimit: null,
      Tags: [],
      DiscountType: DiscountType.BuyMoreSaveMore
    } as Discount)
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
      <Grid container spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ p: 3 }}>

            <Grid container>
              <Grid item xs={"auto"}>
                <Tooltip title="Go back to list">
                  <IconButton aria-label="back" edge="start" sx={{ mr: 1 }} href="/promos">
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Title>Add Promotion</Title>
                <Typography color="text.secondary">Site coupons are discounts used on the website when enterning a promo code during checkout</Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <AddButton disabled={!changesAvailable} errors={errors} savePromotion={handleAddPromotion} />
              </Grid>
            </Grid>
            {discount ? <PromoViewCommonFields discount={discount} handleUpdate={handleUpdate} handleErrors={handleErrors} handleAddNewTag={handleAddNewTag} /> : <></>}

            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" sx={{ background: 'white', pr: 1 }}> Discount Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedDiscountType}
                    label="Age"
                    onChange={handleChangeDiscountType}
                  >
                    <MenuItem value={DiscountType.Unknown}>Select Discount Type</MenuItem>
                    <MenuItem value={DiscountType.BuyMoreSaveMore}>Buy More Save More</MenuItem>
                    <MenuItem value={DiscountType.Credit}>Credit</MenuItem>
                    <MenuItem value={DiscountType.GiftItem}>Gift Item</MenuItem>
                    <MenuItem value={DiscountType.Product}>Product</MenuItem>
                    <MenuItem value={DiscountType.ProductGroup}>Product Group</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {discount ? <DiscountDetails discount={discount} handleUpdate={handleUpdate} handleErrors={handleErrors} /> : <></>}
          </Paper>
        </Box>
      </Grid>
      <LoadingSpinner active={spinnerActive} />
      <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
    </Container>
  );
}
