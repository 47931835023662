
export enum AndOrLogic {
    and = 1,
    or = 2
}

export enum DiscountLogic {
    Unknown = 0,
    absolute = 1,
    relative = 2
}

export enum DiscountType {
    Unknown = "unknown",
    Product = "product",
    GiftItem = "gift",
    BuyMoreSaveMore = "buy_more_save_more",
    ProductGroup = "bundle",
    Credit = "coupon"
}

export enum BMSMVariant {
    Unknown = "unknown",
    Products = "products",
    CartValue = "cart_value"
}

export enum AlertType {
    error = "error",
    warning = "warning",
    info = "info",
    success = "success"
}