import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DiscountTable from '../components/DiscountTable';
import Title from '../components/Title';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Card, CardContent, Chip, FormControl, FormControlLabel, FormLabel, IconButton, Menu, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker';
import DiscountEngineApiHelper from '../helpers/DiscountEngineApiHelper';
import LoadingSpinner from '../components/LoadingSpinner';
import FilterHelper from '../helpers/FilterHelper';
import TableFilter, { TableFilterType, SelectedFilters } from './components/TableFilter';
import { TableCouponData } from '../data/Interfaces';
import DeleteDialog from './components/DeleteDialog';
import Alerts, { WebsiteAlert } from '../components/Alerts';
import { AlertType } from "../data/Enums";

const SelectedFiltersDisplay = (props: { filters: SelectedFilters[], handleRemove(filter: SelectedFilters): void }) => {

  return (props.filters.length === 0) ? (<></>) : (
    <Box sx={{ width: '100%', my: 1 }}>
      <Typography color="GrayText" sx={{ float: 'left', lineHeight: "200%", mr: 2 }}>Active Filters:</Typography> {props.filters.map(filter => <Chip sx={{ mr: 1 }} label={`${filter.name}: ${filter.value}`} onDelete={() => props.handleRemove(filter)} />)}
    </Box>
  )
}

const discountEngineApiHelper = new DiscountEngineApiHelper();
const filterHelper = new FilterHelper();

export default function Coupons() {
  const [rows, setRows] = React.useState<TableCouponData[]>([]);
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters[]>([]);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [deleteDialogActive, setDeleteDialogActive] = React.useState(false);
  const [deleteDialogId, setDeleteDialogId] = React.useState('');
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: '',
    open: false
  } as WebsiteAlert);

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleDeleteDialog = (id: string) => {
    setSpinnerActive(true);
    setDeleteDialogActive(false);

    discountEngineApiHelper.deleteCoupon(deleteDialogId).then(() => {
      setSpinnerActive(true);
      setRows(rows.filter(row => {
        return row.Id !== deleteDialogId;
      }))
      setSpinnerActive(false);
      handleAlertOpen(AlertType.success, 'Entry succesfully deleted');
    });
  }

  const handleCloseDialog = () => setDeleteDialogActive(false);

  const handleView = (id: string) => { alert(`Need to handle view for id ${id}`) }

  const handleDelete = (id: string) => {
    setDeleteDialogId(id);
    setDeleteDialogActive(true);
  }

  const handleDuplicate = (id: string) => { alert(`Need to handle duplicate for id ${id}`) }

  const filterResults = () => {
    setSpinnerActive(true);
    discountEngineApiHelper.getCoupons().then((rows) => {
      if (rows.isSuccess) {
        filterHelper.filterRows(selectedFilters, rows.value).then((filteredRows) => {
          setRows(filteredRows as TableCouponData[]);
          setSpinnerActive(false);
        });
      }
      else {
        alert(rows.error + "\nPlease refresh the page and try again.");
      }

    });
  }

  const handleSaveFilters = (filters: SelectedFilters[]) => setSelectedFilters(filters);

  const handleRemoveFilter = (filter: SelectedFilters) => {
    const remainingFilters = selectedFilters?.filter(value => value !== filter) ?? null;
    setSelectedFilters(remainingFilters);
  }

  React.useEffect(() => {
    filterResults();
  }, [selectedFilters]);

  React.useEffect(() => {
    setSpinnerActive(true);
    discountEngineApiHelper.getCoupons().then((rows) => {
      if (rows.isSuccess) {
        filterHelper.filterRows(selectedFilters, rows.value).then((filteredRows) => {
          setRows(filteredRows as TableCouponData[]);
          setSpinnerActive(false);
        });
      }
      else {
        alert(rows.error + "\nPlease refresh the page and try again.");
      }
    })
  }, [selectedFilters])

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
      <Grid container spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ p: 3 }}>
            <Grid container>
              <Grid item xs>
                <Title>Site Coupons</Title>
                <Typography color="text.secondary">Site coupons are discounts used on the website when enterning a promo code during checkout</Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <TableFilter type={TableFilterType.Coupons} setFilters={handleSaveFilters} />
              </Grid >
            </Grid >
            <SelectedFiltersDisplay filters={selectedFilters} handleRemove={handleRemoveFilter} />
            <Alerts alert={websiteAlert} handleClose={handleAlertClose} />
            <DiscountTable tableData={rows} handleView={handleView} handleDelete={handleDelete} handleDuplicate={handleDuplicate} />
          </Paper >
        </Box >
      </Grid >
      <LoadingSpinner active={spinnerActive} />
      <DeleteDialog id={deleteDialogId} active={deleteDialogActive} handleDelete={handleDeleteDialog} handleClose={handleCloseDialog} />
    </Container >
  );
}
