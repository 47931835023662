import * as React from 'react';
import { Card, CardContent, Grid, List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import Title from "../../../../components/Title";
import { AvailableProduct, DiscountLogic, ProductGroup } from '@onpurple/discount-engine-types';

export default function SelectedProductGroup(props: { productGroup: ProductGroup }) {
  const { productGroup } = props;
  const [groups, setGroups] = React.useState<{ category: string, options: AvailableProduct[] }[]>([]);

  const calculateDiscountedPrice = (basePrice: number, logic: DiscountLogic, value: number) => {
    if (logic === DiscountLogic.Absolute) {
      const total = basePrice - value;
      return total > 0 ? total : 0;
    }

    if (logic === DiscountLogic.Relative) {
      const percentage = basePrice * (value / 100);
      const total = basePrice - percentage;
      return total > 0 ? total : 0;
    }
  }

  React.useEffect(() => {
    let toAdd = [] as { category: string, options: AvailableProduct[] }[];

    for (let i = 0; i < productGroup.Products.length; i++) {
      const indexOf = toAdd.findIndex(x => x.category === productGroup.Products[i].Category);
      if (indexOf === -1) {
        toAdd.push({
          category: productGroup.Products[i].Category,
          options: [productGroup.Products[i]]
        });
      } else {
        toAdd[indexOf].options.push(productGroup.Products[i]);
      }
    }

    setGroups(toAdd);

  }, [productGroup.Products]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid item xs={12}>
          <Title>Display Name: {productGroup.DisplayName}</Title>
          <Title sx={{ fontSize: 18, color: 'text.secondary' }}>Discount: {productGroup.Logic === DiscountLogic.Absolute ? "$" : ""}
            {productGroup.Value.toFixed(2)}
            {productGroup.Logic === DiscountLogic.Relative ? "%" : ""}</Title>
        </Grid>

        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {groups.map((group) => (
            <li key={`section-${group.category}`}>
              <ul>
                <ListSubheader sx={{ ml: 0, pl: 0, fontSize: 16 }}>{group.category}</ListSubheader>
                {group.options.map((product) => (
                  <ListItem
                    key={`item-${group.category}-${product.Id}`}
                    // secondaryAction={
                    //   <IconButton edge="end" aria-label="delete" onClick={() => { handleDeleteProduct(product.Id) }}>
                    //     <DeleteIcon />
                    //   </IconButton>
                    // }
                    sx={{ my: 0, py: 0 }}
                  >
                    <ListItemText
                      primary={`${product.Name}: ${product.Variation} - $${product.Price.toFixed(2)} - $${calculateDiscountedPrice(product.Price, productGroup.Logic, productGroup.Value)?.toFixed(2)}`}
                      secondary={`SKU: ${product.Sku}`}
                    />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}