import * as React from 'react';
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useProducts } from '../../helpers/apiHooks/useProducts';
import { AvailableProduct } from '@onpurple/discount-engine-types';


export default function AvailableProductSelect(props:
  {
    label: string,
    excludedProducts?: AvailableProduct[],
    multiple: boolean,
    handleSelected(selected: AvailableProduct[] | AvailableProduct | null): void
  }) {
  const productsApi = useProducts();
  const { handleSelected, label, multiple, excludedProducts = [] } = props;
  const [availableProducts, setAvailableProducts] = React.useState<AvailableProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<AvailableProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<AvailableProduct | null>(null);

  React.useEffect(() => {
    productsApi.getAll()
      .then(response => {
        const products = (response.isSuccess && response.data) ? response.data : [] as AvailableProduct[];
        setAvailableProducts(products);
      });
  }, []);

  const isExcluded = (id: string) => {
    let indexOf = excludedProducts.findIndex(x => x.Id === id);
    return indexOf !== -1;
  }


  // <Autocomplete
  //   id="grouped-demo"
  //   options={availableProducts.sort((a, b) => -b.Name.localeCompare(a.Name))}
  //   groupBy={(option) => option.Category}
  //   getOptionLabel={(option) => `${option.Name}: ${option.Variation} (${option.Sku})`}
  //   renderInput={(params) => <TextField {...params} label="Select Product" />}
  //   value={selectedProduct.Value}
  //   onChange={(event: any, newValue: AvailableProduct | null) => {
  //     handleSetSelectedProduct(newValue);
  //   }}
  // />



  const handleSingleChange = (newValue: AvailableProduct | null) => {
    handleSelected(newValue);
    setSelectedProduct(newValue);
  };

  const handleMultipleChange = (newValue: AvailableProduct[] | null) => {
    let toAdd = newValue?.filter(x => x.Id !== "" && !isExcluded(x.Id)) ?? [];
    let addAll = newValue?.filter(x => x.Id === "") ?? [];

    for (let i = 0; i < addAll.length; i++) {
      let all = availableProducts.filter(x => x.Category === addAll[i].Category && x.Id !== "" && !isExcluded(x.Id) && !toAdd.map(x => x.Id).includes(x.Id));
      toAdd = [...toAdd, ...all];
    }
    handleSelected(toAdd);
    setSelectedProducts(toAdd);
  };


  // <Autocomplete
  //   id="grouped-demo"
  //   options={availableProducts.sort((a, b) => -b.Name.localeCompare(a.Name))}
  //   groupBy={(option) => option.Category}
  //   getOptionLabel={(option) => `${option.Name}: ${option.Variation} (${option.Sku})`}
  //   renderInput={(params) => <TextField {...params} label="Select Product" />}
  //   value={selectedProduct.Value}
  //   onChange={(event: any, newValue: AvailableProduct | null) => {
  //     handleSetSelectedProduct(newValue);
  //   }}
  // />
  return (
    <Autocomplete
      multiple={multiple}
      id="grouped-demo"
      disableCloseOnSelect={multiple}
      options={
        availableProducts
          .sort((a, b) => -b.Category.localeCompare(a.Category) || -b.Name.localeCompare(a.Name))
      }
      renderTags={() => null}
      groupBy={(option) => option.Category}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple &&
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
              disabled={isExcluded(option.Id)}
            />}
          <Typography sx={{ color: (isExcluded(option.Id)) ? 'GrayText' : 'black' }}>
            {option.Id === "" ? `${option.Name}` : `${option.Name}: ${option.Variation} (${option.Sku})`}
          </Typography>
        </li>
      )}
      getOptionLabel={(option) => `${option.Name}: ${option.Variation} (${option.Sku})`}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={multiple ? selectedProducts : selectedProduct}
      onChange={(event: any, newValue: AvailableProduct[] | AvailableProduct | null) => {
        if (Array.isArray(newValue)) {
          handleMultipleChange(newValue);
        } else {
          handleSingleChange(newValue);
        }
      }}
    />
  )
}