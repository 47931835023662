import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LongMenu from '../../components/LongMenu';
import { TableCampaignData } from '../../data/Interfaces';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
function Row(props: { row: TableCampaignData, handleView(id: string): void, handleDelete(id: string): void }) {
    const { row, handleView, handleDelete } = props;
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                    {row.Name}
                </TableCell>
                <TableCell align="right">{row.Active ? <Chip sx={{ width: '100%' }} label="yes" color="primary" /> : <Chip sx={{ width: '100%' }} label="no" color="warning" />}</TableCell>
                <TableCell align="right">{row.Start !== null ? dayjs(row.Start).format('MM/DD/YYYY')  : "-"}</TableCell>
                <TableCell align="right">{row.End !== null ? dayjs(row.End).format('MM/DD/YYYY') : "-"}</TableCell>
                <TableCell align="right">{row.Priority}</TableCell>
                <TableCell align="right">
                    <LongMenu options={[
                        { title: "View", onClick: () => { handleView(row.Id) } },
                        { title: "Delete", onClick: () => { handleDelete(row.Id) }, closeOnClick: true }
                    ]} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
export default function CampaignTable(props: { tableData: TableCampaignData[], handleView(id: string): void, handleDelete(id: string): void }) {
    const { handleDelete, handleView } = props;
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Active</TableCell>
                        <TableCell align="right">Start</TableCell>
                        <TableCell align="right">End</TableCell>
                        <TableCell align="right">Priority</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.tableData.map((row) => (
                        <Row key={row.Name} row={row} handleDelete={handleDelete} handleView={handleView} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}