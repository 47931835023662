import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface LongMenuOption {
  title: string,
  onClick(): void,
  closeOnClick?: boolean
}

export interface LongMenuSettings {
  /** @defaultValue '216' */
  maxHeight?: number,
  /** @defaultValue '20ch' */
  width?: string
}

export interface LongMenuProps {
  options: LongMenuOption[]
  settings?: LongMenuSettings
}

export default function LongMenu(props: LongMenuProps) {
  const { settings } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        className="class-name-here"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: settings?.maxHeight ?? '216',
            width: settings?.width ?? '20ch',
          },
        }}>
        {props.options.map(option => (
          <MenuItem onClick={() => {
            option.onClick();

            if (option.closeOnClick) {
              handleClose();
            }
          }}>{option.title}</MenuItem>
        ))}
      </Menu>
    </div>
  );
}