import * as React from 'react';
import { Card, CardContent, List, ListItem, ListItemText, ListSubheader, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Title from "../../components/Title";
import { AvailableProduct } from '@onpurple/discount-engine-types';

export default function SelectedProductList(props: {
  title: string,
  selectedProducts: AvailableProduct[],
  handleDeleteProduct(id: string): void
}) {
  const { selectedProducts, handleDeleteProduct, title } = props;
  const [groups, setGroups] = React.useState<{ category: string, options: AvailableProduct[] }[]>([]);

  React.useEffect(() => {
    let toAdd = [] as { category: string, options: AvailableProduct[] }[];

    for (let i = 0; i < selectedProducts.length; i++) {
      const indexOf = toAdd.findIndex(x => x.category === selectedProducts[i].Category);
      if (indexOf === -1) {
        toAdd.push({
          category: selectedProducts[i].Category,
          options: [selectedProducts[i]]
        });
      } else {
        toAdd[indexOf].options.push(selectedProducts[i]);
      }
    }

    setGroups(toAdd);

  }, [selectedProducts]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Title>{title}</Title>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {groups.map((group) => (
            <li key={`section-${group.category}`}>
              <ul>
                <ListSubheader sx={{ ml: 0, pl: 0, fontSize: 16 }}>{group.category}</ListSubheader>
                {group.options.map((product) => (
                  <ListItem
                    key={`item-${group.category}-${product.Id}`}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => { handleDeleteProduct(product.Id) }}>
                        <DeleteIcon />
                      </IconButton>
                    }
                    sx={{ my: 0, py: 0 }}
                  >
                    <ListItemText
                      primary={`${product.Name}: ${product.Variation} - $${product.Price.toFixed(2)}`}
                      secondary={`SKU: ${product.Sku}`}
                    />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}