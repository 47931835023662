import { Autocomplete, Chip, FormControlLabel, Grid, Stack, Switch, TextField } from "@mui/material";
import React from "react";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useTags } from "../../../helpers/apiHooks/useTags";
import { Dayjs } from "dayjs";
import { Field } from "../../../data/Field";
import { Discount, Tag } from '@onpurple/discount-engine-types';
import { ApiResponse } from "../../../helpers/ApiHelper";
import Alerts, { WebsiteAlert } from "../../../components/Alerts";
import { AlertType } from "../../../data/Enums";

export default function DiscountCommonFields(props: {
  discount: Discount | null,
  handleUpdate(discount: Discount): void,
  handleErrors(error: string[]): void,
  handleAddNewTag(tag: Tag): void
}) {
  const { discount, handleUpdate, handleErrors, handleAddNewTag } = props;
  const tagHelper = useTags();
  const [availableTags, setAvailableTags] = React.useState<string[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [tagsAdded, setTagsAdded] = React.useState<string[]>([]);
  const [name, setName] = React.useState<Field<string>>(new Field<string>("Name", discount?.Name ?? ""));

  const [displayName, setDisplayName] = React.useState<Field<string>>(new Field<string>("Display Name", discount?.DisplayName ?? ""));
  const [description, setDescription] = React.useState<Field<string>>(new Field<string>("Description", discount?.Description ?? ""));
  const [priority, setPriority] = React.useState<Field<number | null>>(new Field<number | null>("Priority", discount?.Priority ?? 0));
  const [stacking, setStacking] = React.useState<Field<boolean>>(new Field<boolean>("Stacking", discount?.Stacking ?? false));
  const [active, setActive] = React.useState<Field<boolean>>(new Field<boolean>("Active", discount?.Active ?? false));
  const [startDate, setStartDate] = React.useState<Field<Dayjs | null>>(new Field<Dayjs | null>("Start Date", discount?.StartDate ?? null));
  const [expirationDate, setExpirationDate] = React.useState<Field<Dayjs | null>>(new Field<Dayjs | null>("Expiration Date", discount?.ExpirationDate ?? null));
  const [multiApplyLimit, setMultiApplyLimit] = React.useState<Field<number | null>>(new Field<number | null>("Multi Apply Limit", discount?.MultiApplyLimit ?? 0));

  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: '',
    open: false
  } as WebsiteAlert);

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = name.Update(event.target.value, !event.target.value.replace(/\s/g, '').length ? "Name Required" : "");
    setName(value);
    handleUpdate(discount ? { ...discount, Name: value.Value } : { Name: value.Value } as Discount);
  };

  const handleSetDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = displayName.Update(event.target.value);
    setDisplayName(value);
    handleUpdate(discount ? { ...discount, DisplayName: value.Value } : { DisplayName: value.Value } as Discount);
  };

  const handleSetDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = description.Update(event.target.value);
    setDescription(value);
    handleUpdate(discount ? { ...discount, Description: value.Value } : { Description: value.Value } as Discount);
  };

  const handleSetPriority = (event: React.ChangeEvent<HTMLInputElement>) => {

    let error = "";

    if (!event.target.value.replace(/\s/g, '').length) {
      error = "Priority must have a value";
    }
    else if (parseInt(event.target.value) < 0) {
      error = "Priority must be greater than 0";
    }


    let value = priority.Update(parseInt(event.target.value), error);
    setPriority(value);
    handleUpdate(discount ? { ...discount, Priority: value.Value } : { Priority: value.Value } as Discount);
  };

  const handleSetStacking = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = stacking.Update((event.target.checked));
    setStacking(value);
    handleUpdate(discount ? { ...discount, Stacking: value.Value } : { Stacking: value.Value } as Discount);
  };

  const handleSetActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = active.Update((event.target.checked));
    setActive(value);
    handleUpdate(discount ? { ...discount, Active: value.Value } : { Active: value.Value } as Discount);
  };

  const handleSetStartDate = (day: Dayjs | null) => {
    let value = startDate.Update(day);
    setStartDate(value);
    handleUpdate(discount ? { ...discount, StartDate: value.Value } : { StartDate: value.Value } as Discount);
  };

  const handleSetExpirationDate = (day: Dayjs | null) => {
    let value = expirationDate.Update(day);
    setExpirationDate(value);
    handleUpdate(discount ? { ...discount, ExpirationDate: value.Value } : { ExpirationDate: value.Value } as Discount);
  };

  const handleSetMultiApplyLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = multiApplyLimit.Update(parseInt(event.target.value), parseInt(event.target.value) < 0 ? "Multi Apply Limit must be greater than 0" : "");
    setMultiApplyLimit(value);
    handleUpdate(discount ? { ...discount, MultiApplyLimit: value.Value } : { MultiApplyLimit: value.Value } as Discount);
  };

  const handleTagChange = (tags: string[]) => {
    for (let i = 0; i < tags.length; i++) {
      if (!availableTags.includes(tags[i])) {
        if (!tagsAdded.includes(tags[i])) {
          setTagsAdded([...tagsAdded, tags[i]]);
          handleAddNewTag({ Text: tags[i] });
        }
      }
    }

    setSelectedTags(tags);
    handleUpdate(discount ? { ...discount, Tags: tags } : { Tags: tags } as Discount);
  }

  React.useEffect(() => {
    tagHelper.getAll()
      .then((response: ApiResponse<Tag[]>) => {
        if (!response.isSuccess || !response.data) {
          handleAlertOpen(AlertType.error, response.error);
        } else {
          const tags = response.data;
          const toAdd = tags.map(x => x.Text).sort((a, b) => a.localeCompare(b));
          setAvailableTags(toAdd);
          setSelectedTags(
            discount?.Tags.map(x => x) ?? [])
        }
      });
  }, []);

  React.useEffect(() => {
    let errors = [] as string[];

    errors.push(name.Error);
    errors.push(priority.Error);
    errors.push(multiApplyLimit.Error);

    handleErrors(errors.filter(x => x !== ""));
  }, [name, priority, multiApplyLimit]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            id="outlined-search"
            label={name.Title}
            value={name.Value}
            onChange={handleSetName}
            error={name.Error !== ""}
            helperText={name.Error ? name.Error : ""} />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            type="number"
            id="outlined-search"
            label={priority.Title}
            value={priority.Value}
            onChange={handleSetPriority}
            error={priority.Error !== ""}
            helperText={priority.Error ? priority.Error : ""} />
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} sx={{ ml: 3, pt: 1 }}>
            <FormControlLabel control={<Switch checked={stacking.Value} onChange={handleSetStacking} />} label={stacking.Title} />
            <FormControlLabel control={<Switch checked={active.Value} onChange={handleSetActive} />} label={active.Title} />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <TextField fullWidth id="outlined-search" label={displayName.Title} value={displayName.Value} onChange={handleSetDisplayName} />
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MobileDatePicker
                inputFormat="MMMM DD, YYYY"
                label={startDate.Title}
                value={startDate.Value}
                onChange={handleSetStartDate}
                renderInput={(params) => <TextField fullWidth id="start-date" {...params} />}
              />
            </Grid>
            <Grid item xs={4}>
              <MobileDatePicker
                inputFormat="MMMM DD, YYYY"
                label={expirationDate.Title}
                value={expirationDate.Value}
                onChange={handleSetExpirationDate}
                renderInput={(params) => <TextField fullWidth id="expire-date" {...params} />}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="number"
                fullWidth
                id="outlined-search"
                label={multiApplyLimit.Title}
                value={multiApplyLimit.Value}
                onChange={handleSetMultiApplyLimit}
                error={multiApplyLimit.Error !== ""}
                helperText={multiApplyLimit.Error ? name.Error : ""} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label={description.Title}
            multiline
            rows={2}
            value={description.Value}
            onChange={handleSetDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="tags-filled"
            multiple
            freeSolo
            options={[...availableTags, ...tagsAdded].sort((a, b) => a.localeCompare(b))}
            value={selectedTags}
            onChange={(event: React.SyntheticEvent<Element, Event>, value: string[]) => {
              handleTagChange(value);
            }}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                return availableTags.includes(option) ?
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} /> :
                  <Chip variant="outlined" color="success" label={option} {...getTagProps({ index })} />
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tags"
                placeholder="Select Tag"
              />
            )}
          />
        </Grid>
      </Grid>
      <Alerts alert={websiteAlert} handleClose={handleAlertClose} />
    </LocalizationProvider>
  )
}