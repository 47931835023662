import * as React from 'react';
import { Box, Chip, Grid, TextField, Typography } from "@mui/material";
import Title from "../../../../components/Title";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import { Field } from '../../../../data/Field';
import { useProducts } from '../../../../helpers/apiHooks/useProducts';
import AvailableProductSelect from '../../AvailableProductSelect';
import { AvailableProduct, Discount, GiftItemMethod } from '@onpurple/discount-engine-types';

export default function GiftItemSection(props:
  {
    discount: Discount,
    handleUpdate(details: GiftItemMethod): void,
    handleErrors(error: string[]): any
  }) {
  const { discount, handleUpdate, handleErrors } = props;
  const productsApiHelper = useProducts();
  const groupMethod = discount.DiscountMethod as GiftItemMethod;
  const [selectedProduct, setSelectedProduct] = React.useState<Field<AvailableProduct | null>>(new Field("Selected Product", null));
  const [selectedGifts, setSelectedGifts] = React.useState<Field<AvailableProduct[]>>(new Field("Selected Gifts", [] as AvailableProduct[]));
  const [quantity, setQuantity] = React.useState<Field<number>>(new Field("Quantity", groupMethod.Quantity ?? 1));

  const updatePromotion = () => {
    // handleUpdate({
    //   ...groupMethod,
    //   ProductQuantity: quantity.Value,
    //   FreeProductIds: selectedGifts.Value.map(x => x.Id)
    // });
  }
  const handleSetSelectedProduct = (product: AvailableProduct | AvailableProduct[] | null) => {
    const toAdd = selectedProduct.Update(product as AvailableProduct, !product ? 'Must select product' : "");
    setSelectedProduct(toAdd);
    updatePromotion();
  }


  const handleSetSelectedGifts = (products: AvailableProduct | AvailableProduct[] | null) => {
    products = products as AvailableProduct[];
    setSelectedGifts(selectedGifts.Update(products ?? [], (products && products.length <= 0) ? 'Must select at least 1 free gift' : ""));
    updatePromotion();
  }


  const handleSetQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setQuantity(quantity.Update(value, value < 0 ? 'Quantity must be greater than 0' : ""));
    updatePromotion();
  }

  const hasError = (field: Field<any>) => {
    return !field.Error ? false : field.Error !== "";
  }

  React.useEffect(() => {
    let errors = [] as string[];

    if (quantity.Error && quantity.Error !== "") {
      errors.push(quantity.Error);
    }

    if (selectedProduct.Error && selectedProduct.Error !== "") {
      errors.push(selectedProduct.Error);
    }

    if (selectedGifts.Error && selectedGifts.Error !== "") {
      errors.push(selectedGifts.Error);
    }

    handleErrors(errors);
  }, [quantity, selectedProduct, selectedGifts])

  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Title>Product Discount Details</Title>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <AvailableProductSelect
              multiple={false}
              label='Select Product'
              handleSelected={handleSetSelectedProduct}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth type="number"
              id="outlined-search"
              label="Quantity"
              value={quantity.Value}
              error={hasError(quantity)}
              helperText={quantity?.Error}
              onChange={handleSetQuantity}
              disabled={selectedProduct === null} />
          </Grid>

          <Grid item xs={12}>
            <AvailableProductSelect
              multiple={true}
              label='Select Gifts'
              handleSelected={handleSetSelectedGifts}
            />


          </Grid>
        </Grid>



      </Grid>
      <Grid item xs={12}>
        <Box sx={{ minWidth: 275 }}>
          <Title sx={{ mb: 2 }}>
            Discount Details
          </Title>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Selected Product:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  <Typography color="text.primary">
                    {selectedProduct.Value?.Name}
                  </Typography>
                </TableCell>
              </TableRow>


              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Product Category:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  <Typography color="text.primary">
                    {selectedProduct.Value?.Category}
                  </Typography>
                </TableCell>
              </TableRow>


              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Product Sku:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  <Typography color="text.primary">
                    {selectedProduct.Value?.Sku}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Size:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  <Typography color="text.primary">
                    {selectedProduct.Value?.Variation}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Price:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  <Typography color="text.primary">
                    ${selectedProduct.Value?.Price.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>


              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Selected Gifts:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  {selectedGifts.Value?.map(gift => (
                    <Chip
                      label={`${gift.Name}: ${gift.Variation} (${gift.Sku})`}
                      icon={<DoneIcon />}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </TableCell>
              </TableRow>


              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell scope="row" sx={{ width: 'auto', p: 1, pr: 0 }}>
                  <Typography sx={{ m: 0, p: 0 }} color="text.secondary">
                    Gift Value:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto', p: 1, pl: 0 }}>
                  <Typography color="text.primary">
                    ${selectedGifts.Value?.map(x => x.Price).reduce((previous, value) => previous + value, 0).toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  );
}



