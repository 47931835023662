import dayjs, { Dayjs } from "dayjs";
import { DE_Discount } from "../data/Dtos";
import {
    TableDiscountData,
    TableCouponData,
    TableCampaignData,
    RowData
} from "../data/Interfaces";
import { SelectedFilters } from "../pages/components/TableFilter";
import DiscountEngineApiHelper from "./DiscountEngineApiHelper";

export default class FilterHelper {

    async filterRows(selectedFilters: SelectedFilters[], rows: (TableDiscountData[] | TableCouponData[] | TableCampaignData[])) {
        let filteredRows = rows;

        for (const filter of selectedFilters) {
            let filterKey = filter.name.toLowerCase();
            let filterValue = `${filter.value}`.toLowerCase();
            if (filterKey === 'coupon') {
                let discountEngineApiHelper = new DiscountEngineApiHelper();
                let couponResponse = await discountEngineApiHelper.getRawCouponByCode(filter.value);
                let coupon: DE_Discount;
                if (couponResponse.isSuccess) {
                    coupon = couponResponse.value;
                }
                filteredRows = ((filteredRows as TableCouponData[]).filter(row => {
                    if (!coupon) {
                        return false;
                    }
                    return coupon.name === row.Name;
                }) as TableCouponData[]);
            }

            filteredRows = (filteredRows as any).filter((row: (TableDiscountData | TableCouponData | TableCampaignData)) => {

                let rowFilterValue = (row[(filterKey.charAt(0).toUpperCase() + filterKey.slice(1)) as (keyof typeof row)]);

                switch (filterKey) {
                    case 'active':
                        return filterValue === '' || `${rowFilterValue}`.toLowerCase() === filterValue;
                    case 'start':
                    case 'end':
                        rowFilterValue = rowFilterValue as Dayjs;
                        let selectedDate = dayjs(filter.value);
                        return rowFilterValue &&
                            selectedDate.date() === rowFilterValue.date() &&
                            selectedDate.month() === rowFilterValue.month() &&
                            selectedDate.year() === rowFilterValue.year();
                    case 'coupon':
                        return true;
                    case 'name':
                    default:
                        return `${rowFilterValue}`.toLowerCase().includes(filterValue)
                }
            });
        };
        return filteredRows;
    }
}