import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Campaign } from '@onpurple/discount-engine-types';
import CampaignDiscountTable from "./CampaignDiscountTable";


export interface CampaignDiscountsProps {
  campaign: Campaign,
  setHasChanges(boolean: boolean): void,
}

export default function CampaignDiscounts(props: CampaignDiscountsProps) {
  const { campaign, setHasChanges } = props;

  React.useEffect(() => {

  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%', mt: 2 }}>
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CampaignDiscountTable discounts={campaign.Discounts} campaign={campaign} setHasChanges={setHasChanges} />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </LocalizationProvider>
  )
}