import * as React from 'react';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Title from '../../components/Title';
import { Button, Divider, IconButton, List, ListItem, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner';
import { AlertType } from '../../data/Enums';
import DiscountCommonFields from '../components/Discounts/DiscountCommonFields';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateAlert, { WebsiteAlert } from '../../components/Alerts';
import DiscountDetails from '../components/Discounts/DiscountDetails';
import { usePromotions } from '../../helpers/apiHooks/usePromotions';
import { useTags } from '../../helpers/apiHooks/useTags';
import { Discount, Tag } from '@onpurple/discount-engine-types'


const SaveButton = (props: { disabled: boolean, errors: string[], savePromotion(): void }) => {
  const { disabled, savePromotion, errors = [] } = props;
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);

  const handleErrorPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleCloseErrorPopover = () => {
    setPopoverAnchor(null);
  };

  const popoverOpen = Boolean(popoverAnchor);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  return errors.length === 0 ? (
    <Button variant="contained" disabled={disabled} onClick={savePromotion}>Save</Button>
  ) : (
    <>
      <Tooltip title="Save promotion">
        <Button variant="contained" aria-describedby={popoverId} color="error" onClick={handleErrorPopover}>Save</Button>
      </Tooltip>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={popoverAnchor}
        onClose={handleCloseErrorPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List dense={false}>
          {errors.map((error, index) => (
            <>
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  primary={(<Typography sx={{ fontSize: 15, color: 'red' }}>{error}</Typography>)}
                // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
              {(index !== errors.length - 1) ? <Divider component="li" /> : <></>}
            </>
          ))}
        </List>
      </Popover>
    </>
  );
}

export default function PromoView() {
  const apiHelper = usePromotions();
  const tagApiHelper = useTags();
  const [discount, setDiscount] = React.useState<Discount | null>(null);
  const [spinnerActive, setSpinnerActive] = React.useState<boolean>(false);
  const [changesAvailable, setChangesAvailable] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [newTags, setNewTags] = React.useState<Tag[]>([]);
  const [websiteAlert, setWebsiteAlert] = React.useState<WebsiteAlert>({
    type: AlertType.success,
    message: "",
    open: false
  } as WebsiteAlert)

  let params = useParams();
  const promoId = params.id;

  const handleAlertClose = () => setWebsiteAlert({ ...websiteAlert, open: false });
  const handleAlertOpen = (type: AlertType, message: string) => setWebsiteAlert({ ...websiteAlert, open: true, type: type, message: message });

  const handleUpdate = (discount: Discount) => {
    setDiscount(discount);
    setChangesAvailable(true);
  };

  const handleAddNewTag = (tag: Tag) => setNewTags([...newTags, tag]);

  const handleErrors = (errors: string[]) => {
    setErrors(errors);
  }

  const handleSavePromotion = () => {
    // Turn on spinner, save, remove spinner, show success popup in corner
    if (!discount) {
      return;
    }

    setSpinnerActive(true);
    apiHelper.update(discount)
      .then(() => {
        newTags.forEach(tag => {
          tagApiHelper.add(tag.Text);
        });

        setSpinnerActive(false);
        setChangesAvailable(false);
        handleAlertOpen(AlertType.success, "Promotion Updated");
      })
      .catch(error => {
        handleAlertOpen(AlertType.error, error);
        setSpinnerActive(false);
      })
  }

  React.useEffect(() => {
    if (promoId) {

      apiHelper.get(promoId)
        .then(response => {
          if (response.isSuccess) {
            setDiscount(response.data ?? null);
          } else {
            handleAlertOpen(AlertType.error, response.error);
          }
        })
        .catch(exception => {
          console.log(exception);
        });
    }
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
      <Grid container spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ p: 3 }}>

            <Grid container>
              <Grid item xs={"auto"}>
                <Tooltip title="Go back to list">
                  <IconButton aria-label="back" edge="start" sx={{ mr: 1 }} href="/promos">
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Title>View Promotion</Title>
                <Typography color="text.secondary">Site coupons are discounts used on the website when enterning a promo code during checkout</Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <Tooltip title="Delete promotion">
                  <Button variant="outlined" color="error" sx={{ mr: 2 }}>Delete</Button>
                </Tooltip>
                <SaveButton disabled={!changesAvailable} errors={errors} savePromotion={handleSavePromotion} />
              </Grid>
            </Grid>
            {discount ? <DiscountCommonFields discount={discount} handleUpdate={handleUpdate} handleErrors={handleErrors} handleAddNewTag={handleAddNewTag} /> : <></>}
            {discount ? <DiscountDetails discount={discount} handleUpdate={handleUpdate} handleErrors={handleErrors} /> : <></>}
          </Paper>
        </Box>
      </Grid>
      <LoadingSpinner active={spinnerActive} />
      <CreateAlert alert={websiteAlert} handleClose={handleAlertClose} />
    </Container>
  );
}
