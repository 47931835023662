import * as React from 'react';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { Grid, Paper } from '@mui/material';

interface TitleProps {
  children?: React.ReactNode;
  addRow?: boolean;
  sx?: SxProps<Theme> | undefined;
}

export default function Title(props: TitleProps) {
  const { children, sx, addRow } = props;

  const Title = ({ style }: { style?: SxProps<Theme> | undefined }) => (
    <Typography component="h2" variant="h6" color="primary" sx={style}>
      {children}
    </Typography>
  );

  return addRow ?
    <Grid container sx={sx}>
      <Grid item xs>
        <Title />
      </Grid>
    </Grid>
    : <Title style={sx} />;
}
