import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Checkbox } from '@mui/material';
import { TableDiscountData } from '../../../../data/Interfaces';
import { Campaign } from '@onpurple/discount-engine-types';

function Row(props: { row: TableDiscountData, selectedRows: string[], setSelected(selected: string[]): void }) {
  const { row, setSelected, selectedRows } = props;

  const isSelected = (name: string) => selectedRows.indexOf(name) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row.Id)}
        role="checkbox"
        aria-checked={isSelected(row.Id)}
        tabIndex={-1}
        key={row.Id}
        selected={isSelected(row.Id)}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isSelected(row.Id)}
            inputProps={{
              'aria-labelledby': row.Id,
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Name}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export interface DiscountModalTableProps {
  tableData: TableDiscountData[],
  campaign: Campaign,
  handleUpdateDiscounts(ids: String[], campaign: Campaign): void
}

export default function DiscountModalTable(props: DiscountModalTableProps) {
  const { campaign, handleUpdateDiscounts } = props;
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const handleSelectAll = () => {
    if (props.tableData.length > 0 && selectedIds.length === props.tableData.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(props.tableData.map(x => x.Id));
    }
  }

  const handleSetSelected = (selected: string[]) => setSelectedIds(selected);

  const handleAddDiscount = () => {
    handleUpdateDiscounts(selectedIds, campaign);
  }

  return (
    <TableContainer>
      <Button sx={{ float: "right" }} variant="contained" onClick={handleAddDiscount}>Add Discounts</Button>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={selectedIds.length > 0 && selectedIds.length < props.tableData.length}
                checked={props.tableData.length > 0 && selectedIds.length === props.tableData.length}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((row) => (
            <Row key={row.Name} row={row} selectedRows={selectedIds} setSelected={handleSetSelected} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
