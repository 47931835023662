import React from "react";
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from "react-router-dom";
import { Typography } from "@mui/material";

export enum Roles {
  Admin = 'admin',
  Viewer = 'viewer',
  Approver = 'approver',
  Editor = 'editor'
}

const RoleError = (props: { error: string }) => {
  return (
    <Typography>{props.error}</Typography>
  )
}

export const RoleRequired = ({ children, roles, error }: { children: JSX.Element, roles: Roles[], error?: string }) => {
  const { authState } = useOktaAuth();
  //  const { roles, children } = props;

  const hasRoles = () => {
    const claims = authState?.accessToken?.claims;
    let response = roles.every(role => {
      switch (role) {
        case Roles.Admin:
          return claims?.admin;
        case Roles.Viewer:
          return claims?.viewer;
        case Roles.Approver:
          return claims?.approver;
        case Roles.Editor:
          return claims?.editor;
        default:
          return false;
      }
    });
    return response;
  }

  return !hasRoles() ?
    error ? <RoleError error={error} /> : <></>
    : children;
}