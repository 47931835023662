import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Card, CardContent, FormControl, FormControlLabel, FormLabel, IconButton, Menu, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker';
import CloseIcon from '@mui/icons-material/Close';

export enum TableFilterType {
    Coupons = 1,
    Promotions = 2,
    Campaigns = 3
}

export interface SelectedFilters {
    name: string,
    value: string
}

export default function TableFilter(props: {
    type: TableFilterType,
    setFilters(filters: SelectedFilters[]): void
}) {
    const [active, setActive] = React.useState<string>("");
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const [name, setName] = React.useState<string | null>(null);
    const [coupon, setCoupon] = React.useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive((event.target as HTMLInputElement).value);
    };

    const handleSubmit = () => {
        let filters = [] as SelectedFilters[];
        if (active && active !== "") {
            filters.push({ name: "active", value: active });
        }

        if (startDate) {
            filters.push({ name: "start", value: startDate.toDateString() });
        }

        if (endDate) {
            filters.push({ name: "end", value: endDate.toDateString() });
        }

        if (name && name !== "") {
            filters.push({ name: "name", value: name });
        }

        if (coupon && coupon !== "") {
            filters.push({ name: "Coupon", value: coupon });
        }

        props.setFilters(filters);
        handleClose();
    }

    return (
        <>
            <Tooltip title="Filter list">
                <IconButton
                    onClick={handleClick}
                    aria-controls={open ? 'filter-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <FilterListIcon />
                </IconButton>
            </Tooltip>

            <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={() => { }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

            >
                <Card>
                    <CardContent sx={{ mb: 0, pb: 0, maxWidth: "350px" }}>
                        <FormControl sx={{ float: 'right', cursor: 'pointer' }}>
                            <CloseIcon onClick={handleClose} />
                        </FormControl>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">&#8288;Active</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={active}
                                onChange={handleActiveChange}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="True" />
                                <FormControlLabel value="false" control={<Radio />} label="False" />
                                <FormControlLabel value="" control={<Radio />} label="Both" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Start Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue: Date | null) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">End Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue: Date | null) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Name</FormLabel>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                value={name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2, display: (props.type !== TableFilterType.Coupons) ? "none" : "inline-flex" }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Coupon Code</FormLabel>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                value={coupon}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCoupon(event.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 3 }}>
                            <Button variant="contained" onClick={handleSubmit}>Filter</Button>
                        </FormControl>

                    </CardContent>
                </Card>
            </Menu>
        </>
    )
}