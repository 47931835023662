import React from "react";

export interface Field<T> {
  Value: T,
  Title: string,
  Error: string,
  Update(value: T, error?: string): Field<T>
}

export class Field<T> {
  Value: T;
  Title: string;
  Error: string;

  constructor(title: string, value: T, error: string = "") {
    this.Value = value;
    this.Title = title;
    this.Error = error;
    this.Update = (value: T, error?: string): Field<T> => {
      this.Value = value;
      this.Error = error ?? "";
      return { ...this };
    }
  }
}