import { Box, CircularProgress } from '@mui/material';


export default function LoadingSpinner(props: {active: boolean}) {
    const { active } = props;

    const style = {
        display: 'inline-block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        background: 'rgba(225, 225, 225, 0.3)',
        height: '100%',
        zIndex: '9999',
        textAlign: 'center'
    }

    const spinnerStyle = {

        margin: 'auto',
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }

    return active ? (
        <Box sx={style}>
            <CircularProgress sx={spinnerStyle} />
        </Box>
    ) : (<></>);
}



