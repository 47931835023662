import * as React from 'react';
import { Button, Card, CardContent, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Title from "../../../../components/Title";
import { Field } from '../../../../data/Field';
import AvailableProductSelect from '../../AvailableProductSelect';
import SelectedProductList from '../../SelectedProductList';
import SelectedProductGroup from './SelectedProductGroup';
import { AvailableProduct, DiscountLogic, ProductGroup } from '@onpurple/discount-engine-types';

export default function GroupDiscountCreate(props: {
  handleUpdate(group: ProductGroup): void,
  notDiscountedProducts: AvailableProduct[]
}) {
  const { handleUpdate, notDiscountedProducts } = props;

  const [discountedGroups, setDiscountedGroups] = React.useState<ProductGroup[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<AvailableProduct[]>([]);
  const [logic, setLogic] = React.useState<DiscountLogic>(DiscountLogic.Absolute);
  const [value, setValue] = React.useState<Field<number>>(new Field("Value", 0));
  const [displayName, setDisplayName] = React.useState<Field<string>>(new Field("Display Name", ""));

  const clearFields = () => {
    setSelectedProducts([]);
    setLogic(DiscountLogic.Absolute);
    setValue(value.Update(0, ""));
    setDisplayName(displayName.Update("", ""));
  }

  const hasErrors = () => {
    return displayName.Error !== "" || displayName.Value === "" || value.Error !== ""
  }

  const handeUpdateDisplayName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.value;
    setDisplayName(displayName.Update(name, name !== "" ? "" : "Display Name Cannot Be Empty"));
  }

  const handeUpdateValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    setValue(value.Update(newValue, newValue < 0 ? "Value cannot be negative" : ""));
  }


  const handleSetSelectedProducts = (products: AvailableProduct[]) => {
    setSelectedProducts(products);
  }

  const handleSetLogic = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setLogic((value === 'absolute') ? DiscountLogic.Absolute : DiscountLogic.Relative);
  }

  const handleDeleteProduct = (id: string) => {
    const updatedProducts = selectedProducts.filter(x => x.Id !== id);
    setSelectedProducts(updatedProducts);

    if (updatedProducts.length === 0) {
      clearFields();
    }
  }

  const saveGroup = () => {

    const toAdd = {
      DisplayName: displayName.Value,
      Logic: logic,
      Value: value.Value,
      Products: selectedProducts
    } as ProductGroup

    // handleUpdate(toAdd);

    setDiscountedGroups([...discountedGroups, toAdd]);
    setSelectedProducts([]);
    clearFields();
  }

  return (
    <>
      <Grid item xs={6}>
        <AvailableProductSelect
          multiple={true}
          label={"Select Discounted Products"}
          handleSelected={handleSetSelectedProducts}
          excludedProducts={notDiscountedProducts}
        />
      </Grid>


      {selectedProducts.length > 0 ? (

        <Card variant="outlined" sx={{ mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Title>Group To Add</Title>
              </Grid>
              <Grid item xs={6}>
                <Button sx={{ float: 'right' }} variant="contained" onClick={saveGroup} disabled={hasErrors()}>Add Group</Button>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth type="text"
                  id="outlined-search"
                  label={displayName.Title}
                  value={displayName.Value}
                  onChange={handeUpdateDisplayName}
                  error={displayName.Error !== ""}
                  helperText={(displayName.Error === "") ? "overwrites the discount" : displayName.Error} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth type="number"
                  id="outlined-search"
                  label={value.Title}
                  InputProps={(logic === 'absolute') ? {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  } : {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  value={value.Value}
                  onChange={handeUpdateValue}
                  error={value.Error !== ""}
                  helperText={value.Error} />
              </Grid>
              <Grid item xs={3}>
                <ToggleButtonGroup
                  color="primary"
                  value={logic}
                  exclusive
                  onChange={handleSetLogic}
                >
                  <ToggleButton value={DiscountLogic.Absolute}>Absolute</ToggleButton>
                  <ToggleButton value={DiscountLogic.Relative}>Relative</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <SelectedProductList title='Selected Products' selectedProducts={selectedProducts} handleDeleteProduct={handleDeleteProduct} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (<></>)}

      {discountedGroups.length > 0 ? (
        <Grid container spacing={3}>

          <Grid item xs={12} sx={{ mt: 2, mb: 0 }}>
            <Title sx={{ pb: 0 }}>Product Groups</Title>
          </Grid>
          {discountedGroups.map(group => (
            <Grid item xs={12}>
              <SelectedProductGroup productGroup={group} />
            </Grid>
          ))}
        </Grid>) : (<></>)}
    </>
  )
}